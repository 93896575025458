import { useEffect, useState } from "react";

const useEventSource = <T,>(url: string) => {
  const [data, setData] = useState<T | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!url) return;

    const eventSource = new EventSource(url);

    eventSource.onmessage = (event) => {
      try {
        const parsedData: T = JSON.parse(event.data);
        setData(parsedData);
      } catch (err) {
        console.error("Error parsing SSE data:", err);
        setError("Failed to parse server response.");
      }
    };

    eventSource.onerror = (err) => {
      console.error("SSE Error:", err);
      setError("Error connecting to the SSE server.");
      eventSource.close();
    };

    return () => {
      eventSource.close();
    };
  }, [url]);

  return { data, error };
};

export default useEventSource;
