// src/App.tsx
import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, Outlet } from 'react-router-dom';
import Login from './pages/Login';
import Register from './pages/Register';
import DashboardL from './pages/DashboardLayout';
import DashboardWelcome from './components/Dashboard/DashboardWelcome';
import DashboardExams from './components/Dashboard/DashboardExams';
import { AuthProvider, useAuth } from './context/AuthContext';
import { ProtectedRouteAdmin, ProtectedRouteSinodal, ProtectedRouteUser} from './components/ProtectedRoute';
import { ThemeProvider } from '@mui/material';
import theme from './theme';
import SecurityLayer from './components/SecurityLayer';
import PrintBlocker from './components/PrintBlocker'; 
import TextSelectionBlocker from './components/TextSelectionBlocker'; 
import Admin from './pages/Admin/Admin';
import DashboardControl from './pages/Admin/DashboardControl';
import ControlExams from './components/DashboardControl/ControlExams';
import ControlMonitoreo from './components/DashboardControl/ControlMonitoreo';
import ControlSustentante from './components/DashboardControl/ControlSustentante';
import ControlReporte from './components/DashboardControl/ControlReporte';
import MonitoreoDetail from './components/DashboardControl/Monitoreo/MonitoreoDetail';
import ExamenEdit from './components/DashboardControl/Examenes/ExamenEdit';
import ShopPage from './pages/Shop';
import { Examen } from './interfaces/IExam';
import SustentanteEdit from './components/DashboardControl/Sustentantes/SustentantesEdit';
import ControlUsuarios from './components/DashboardControl/ControlUsuarios';
import ControlSinodales from './components/DashboardControl/ControlSinodales';

import UsuariosEdit from './components/DashboardControl/Usuarios/UsuariosEdit';
import CheckoutPage from './pages/Checkout';
import ShopLayout from './pages/ShopLayout';
import ResultadosSustentante from './components/Dashboard/ResultadosSustentante';
import UsuariosCreate from './components/DashboardControl/Usuarios/UsuariosCreate';
import QuestionsEdit from './components/DashboardControl/Examenes/QuestionsEdit';
import ControlVentas from './components/DashboardControl/ControlVenta';
import VentasCreate from './components/DashboardControl/Ventas/VentasCreate';
import VentasEdit from './components/DashboardControl/Ventas/VentasEdit';
import SinodalesEdit from './components/DashboardControl/Sinodales/SinodalesEdit';
import SinodalesCreate from './components/DashboardControl/Sinodales/SinodalesCreate';
import ControlCertificaciones from './components/DashboardControl/ControlCertificaciones';
import CertificacionesEdit from './components/DashboardControl/Certificaciones/CertificacionesEdit';
import CertificacionesCreate from './components/DashboardControl/Certificaciones/CertificacionesCreate';
import ExamenUpload from './components/DashboardControl/Examenes/ExamenUpload';
import DashboardSinodal from './pages/DashboardSinodal';
import DashboardSinodalExams from './components/DashboardSinodal/DashboardSinodalExams';
import ForgotPassword from './pages/ForgotPassword';
import RecoverPassword from './pages/RecoverPassword';
import ExamenInscribir from './components/DashboardControl/Examenes/ExamenInscribir';
import SinodalExam from './components/DashboardSinodal/SinodalExam';
import SinodalSustentantes from './components/DashboardSinodal/SinodalSustentantes';
import ThankYouPage from './components/Purchase/ThankYouCard';
import AdminPassword from './pages/Admin/AdminPassword';
import SustentanteCrear from './components/DashboardControl/Sustentantes/SustentantesCreate';
import ValidateDiploma from './pages/ValidateDiploma';
import SearchCertification from './pages/SearchCertification';

const App: React.FC = () => {
  const [cart, setCart] = useState<Examen[]>([]);

  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <Router>
          <TextSelectionBlocker/>
          <Routes>
            {/* Rutas Públicas */}
            <Route path="/" element={<Login />} />
            <Route path="/admin" element={<Admin />} />
            <Route element={<ShopLayout cart={cart} />}>
              <Route path="/shop" element={<ShopPage />} />
            </Route>
            <Route path="/register" element={<Register />} />
            <Route path='/forgotpassword' element={<ForgotPassword />}/>
            <Route path='/adminpassword' element={<AdminPassword />}/>
            <Route path='/recoverpassword/' element={<RecoverPassword />}/>
            <Route path='/validateDiploma/' element={<ValidateDiploma />}/>
            <Route path='/buscarCertificacion/' element={<SearchCertification />}/>

            
            {/* https://examen.cmcmfcert.mx/recoverpassword?token= */}
            {/* Rutas Protegidas */}
            <Route element={<ProtectedRouteAdmin />}>
              <Route path="/control" element={<DashboardControl />}>
                <Route index element={<Navigate to="exams" replace />} />
                <Route path="exams" element={<ControlExams />} />
                <Route path="exams/:id" element={<ExamenEdit />} />
                <Route path="exams/inscribir/:id" element={<ExamenInscribir />} />

                <Route path="preguntas/:id" element={<QuestionsEdit />} />
                <Route path="cargaExamen" element={<ExamenUpload />} />

                <Route path="monitoreo" element={<ControlMonitoreo />} />
                <Route path="monitoreo/:id" element={<MonitoreoDetail />} />
                <Route path="reportes" element={<ControlReporte />} />
                <Route path="sustentantes" element={<ControlSustentante />} />
                <Route path="sustentantes/edit" element={<SustentanteEdit />} />
                <Route path="sustentantes/crear" element={<SustentanteCrear />} />

                <Route path="usuarios" element={<ControlUsuarios />} />
                <Route path="usuarios/:id" element={<UsuariosEdit />} />
                <Route path="usuarios/crear" element={<UsuariosCreate />} />

                <Route path="sinodales" element={<ControlSinodales />} />
                <Route path="sinodales/:id" element={<SinodalesEdit />} />
                <Route path="sinodales/crear" element={<SinodalesCreate />} />

                <Route path="certificaciones" element={<ControlCertificaciones />} />
                <Route path="certificaciones/edit" element={<CertificacionesEdit />} />
                <Route path="certificaciones/crear" element={<CertificacionesCreate />} />

                <Route path="ventas/" element={<ControlVentas />} />
                <Route path="ventas/crear" element={<VentasCreate />} />
                <Route path="ventas/:id" element={<VentasEdit />} />

              </Route>
            </Route>

            <Route element={<ProtectedRouteUser />}>
          <Route
            element={
              <>
                <SecurityLayer />
                <PrintBlocker />
                <Outlet/>
              </>
            }
          >
            <Route path="/dashboard" element={<DashboardL />}>
              <Route index element={<DashboardWelcome />} />
              <Route path="examenes" element={<DashboardExams />} />
              <Route path="resultados" element={<ResultadosSustentante />} />
              <Route path="inscribir" element={<ShopPage />} />
              <Route path="compra" element={<ThankYouPage />} />

            </Route>
           
          </Route>
            </Route>

            <Route element={<ProtectedRouteSinodal />}>
          <Route
            element={
              <>
                <SecurityLayer />
                <PrintBlocker />
                <Outlet/>
              </>
            }
          >
            <Route path="/sinodal" element={<DashboardSinodal />}>
              <Route index element={<DashboardSinodalExams />} />
              <Route path="sustentantes" element={<SinodalSustentantes/>} />
              <Route path="exam" element={<SinodalExam/>} />
             
            </Route>
          </Route>
        </Route>
          </Routes>
        </Router>
      </AuthProvider>
    </ThemeProvider>
  );
};

export default App;
