import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Typography,
  Box,
  Paper,
  CircularProgress,
  Divider,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import { searchCertification } from "../services/examServices";
import SnackbarAlert from "../components/Snackbar/SnackbarAlert";
import { useSnackbar } from "../components/Snackbar/Snackbar";
import { IValidateDiploma } from "../interfaces/IUser";
import logo from "../assets/img/logo.png";
import { getAllCertification } from "../services/certificacionServices";
import { Certificacion } from "../interfaces/ICertificacion";
import { AxiosError } from "axios";

const SearchCertification: React.FC = () => {
  const [certificacionId, setCertificacionId] = useState("");
  const [nombre, setNombre] = useState("");
  const [appelidoP, setApellidoP] = useState("");
  const [apellidoM, setApellidoM] = useState("");
  const [certifications, setCertifications] = useState<Certificacion[]>();

  const [dataExam, setDataExam] = useState<IValidateDiploma| null>();
  const [loading, setLoading] = useState(false);
  const { snackbar, showSnackbar, handleSnackbarClose } = useSnackbar();

  const handleSearch = async () => {
    if (!certificacionId || !nombre || !appelidoP || !apellidoM) {
      showSnackbar("Ambos campos son requeridos", "error");
      setDataExam(null);
      return;
    }
    setLoading(true);
    try {
      const response: IValidateDiploma = await searchCertification(certificacionId, nombre, appelidoP, apellidoM);
      if (response) {
        setDataExam(response);
        showSnackbar("Certificación cargada correctamente", "success");
      }
    } catch (error:  any) {
      showSnackbar( error.message, "error");
      console.error(error.message);
    } finally {
      setLoading(false);
    }
  };
  const fetchCertifications = async () => {
    try{
      console.log("GO GOG O")
    const responses = await getAllCertification();
    if(responses)
    setCertifications(responses);


  } catch (error: any) {
    console.error("Error 2:", error.message);
    showSnackbar(error.message, "error");
  } finally {
  }

  }
  useEffect( () => {
    fetchCertifications();
  },[])
  return (
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" minHeight="100vh">
      <Paper elevation={6} sx={{ padding: 4, borderRadius: 3, maxWidth: 600, textAlign: "center", marginBottom: 4 }}>
        <img src={logo} alt="ExamSys Logo" width={80} style={{ marginBottom: 16 }} />

        <Typography variant="h5" fontWeight="bold" gutterBottom>
          Buscar calificación
        </Typography>
        <TextField
          label="nombre"
          variant="outlined"
          fullWidth
          margin="normal"
          value={nombre}
          onChange={(e) => setNombre(e.target.value)}
        />
                <TextField
          label="Apellido Paterno"
          variant="outlined"
          fullWidth
          margin="normal"
          value={appelidoP}
          onChange={(e) => setApellidoP(e.target.value)}
        />
                <TextField
          label="Apellido Materno"
          variant="filled"
          fullWidth
          margin="normal"
          value={apellidoM}
          onChange={(e) => setApellidoM(e.target.value)}
        />
           <FormControl sx={{ mt: 2,  width: "100%" }}>
                <InputLabel id="estatus-select-label">Selecciona un estatus</InputLabel>
                <Select
                  labelId="estatus-select-label"
                  variant="outlined"
                  value={certificacionId}
                  
                  onChange={(e) => { 
                    // if(onEstatusChange)
                    // onEstatusChange(e.target.value)
                    setCertificacionId(e.target.value)
                  }}
                  label="Estatus"
                  size="small"
                >
                   <MenuItem key={"es-em"} value={""}>{"Selecciona un estatus"}</MenuItem>
    
                  {certifications && certifications.map((mod) => (
                    <MenuItem key={"es-"+mod.id} value={mod.id}>
                      {mod.nombre}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
        

        {/* <TextField
          label="Id certificación"
          variant="outlined"
          fullWidth
          margin="normal"
          value={certificacionId}
          onChange={(e) => setCertificacionId(e.target.value)}
        /> */}
        
        <Button
          variant="contained"
          color="primary"
          fullWidth
          sx={{ marginTop: 2 }}
          onClick={handleSearch}
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} /> : "Search"}
        </Button>
      </Paper>

      {dataExam && (
        <Paper elevation={6} sx={{ padding: 4, borderRadius: 3, maxWidth: 600, textAlign: "center" }}>
          <Typography variant="h6" fontWeight="bold" color="primary">
            {dataExam.certificacionNombre}
          </Typography>
          <Divider sx={{ marginY: 2 }} />
          <Typography>
            <strong>Name:</strong> {dataExam.nombre} {dataExam.apellidoPaterno} {dataExam.apellidoMaterno}
          </Typography>
          <Typography>
            <strong>Final Score:</strong> {dataExam.calificacionFinal}
          </Typography>
          <Typography>
            <strong>Result:</strong> {dataExam.resultado}
          </Typography>
        </Paper>
      )}

      <SnackbarAlert
        open={snackbar.open}
        message={snackbar.message}
        severity={snackbar.severity}
        onClose={handleSnackbarClose}
      />
    </Box>
  );
};

export default SearchCertification;
