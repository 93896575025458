import React, { useState } from "react";
import {
  CardContent,
  TextField,
  Button,
  Typography,
  Box,
  Snackbar,
  Alert,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { Shop, ShopUpdate } from "../../../interfaces/IExam";
import { updateVentas } from "../../../services/ventasService";

const VentasEdit: React.FC = () => {
  const { state } = useLocation();
  const shopItem: Shop = state?.item;
  const [formData, setFormData] = useState<Shop>({ ...shopItem });

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success" as "success" | "error",
  });

  const navigate = useNavigate();

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;

    // Si el campo es "precioVenta", aseguramos que solo acepte números
    const parsedValue = name === "precioVenta" ? Number(value) : value;

    setFormData((prev) => ({
      ...prev,
      [name]: parsedValue,
    }));
  };

  const validateForm = (): boolean => {
    const { precioVenta, imagenUrl } = formData;
    if (!precioVenta || precioVenta <= 0 || !imagenUrl) {
      setSnackbar({
        open: true,
        message: "Por favor, complete todos los campos obligatorios.",
        severity: "error",
      });
      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;

    try {
      const itemToUpdate: ShopUpdate = {
        imagenUrl: formData.imagenUrl,
        precioVenta: Number(formData.precioVenta),
        disponible: true,
      };
      await updateVentas(formData.id, itemToUpdate);
      setSnackbar({
        open: true,
        message: "Catálogo actualizado con éxito.",
        severity: "success",
      });
      setTimeout(() => navigate(-1), 2000);
    } catch (error) {
      console.error("Error al actualizar el catálogo:", error);
      setSnackbar({
        open: true,
        message: "Hubo un error al actualizar el catálogo.",
        severity: "error",
      });
    }
  };

  const handleSnackbarClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") return;
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  return (
    <CardContent>
      <Typography
        sx={{ marginBottom: 5 }}
        variant="h6"
        fontWeight="bold"
        component="h2"
        gutterBottom
      >
        Editar información de examen para el catálogo de venta
      </Typography>

      {/* FORMULARIO */}
      <Box component="form" noValidate sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
        {/* Nombre del Examen */}
        <TextField
          label="Nombre del examen"
          name="examen"
          fullWidth
          value={formData.examen.nombre}
          InputProps={{ readOnly: true }}
        />

        {/* Nota */}
        <TextField
          label="Nota"
          name="nota"
          fullWidth
          value={formData.nota}
          onChange={handleChange}
        />

        {/* URL de Imagen */}
        <TextField
          label="URL de imagen para catálogo"
          name="imagenUrl"
          fullWidth
          value={formData.imagenUrl}
          onChange={handleChange}
        />

        {/* Precio de Venta */}
        <FormControl fullWidth>
          <InputLabel htmlFor="outlined-adornment-amount">Precio de venta</InputLabel>
          <OutlinedInput
            value={formData.precioVenta}
            onChange={handleChange}
            id="outlined-adornment-amount"
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
            label="Precio de venta"
            name="precioVenta"
            type="number"
          />
        </FormControl>

        {/* Botones */}
        <Box sx={{ display: "flex", justifyContent: "space-between", marginTop: 2 }}>
          <Button variant="outlined" onClick={() => navigate(-1)}>
            Cancelar
          </Button>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Guardar
          </Button>
        </Box>
      </Box>

      {/* Snackbar de Notificaciones */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={4000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: "100%" }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </CardContent>
  );
};

export default VentasEdit;
