import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import {
  fetchExamsService,
  fetchGenerarModules,
  iniciarExamen,
} from "../../services/examServices";
import { Examen, Pregunta, ResponseModule } from "../../interfaces/IExam";
import {
  Box,
  CircularProgress,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListSubheader,
} from "@mui/material";
import ExamDetail from "./ExamDetail";
import Questionnaire from "../Questionarie/Questionarie";
import ExamCard from "../Cards/ExamCard";
import { useAuth } from "../../context/AuthContext";
import LoadingModal from "../Loading/LoadingModal";
import NewQuestionnaire from "../Questionarie/NewQuestionarie";
import CropSquareIcon from "@mui/icons-material/CropSquare";
import FolderSpecialIcon from "@mui/icons-material/Star";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import useTokenValidation from "../../hooks/tokenValidation";

const DashboardExams: React.FC = () => {
  const [selectedExam, setSelectedExam] = useState<Examen>();
  const [module, setModules] = useState<ResponseModule>();
  const [examenes, setExams] = useState<Examen[]>([]);
  const [endExam, setEndExam] = useState<boolean>(false);
  
  const [loading, setLoading] = useState<boolean>(false);
  const [isExamStarted, setIsExamStarted] = useState<boolean>(false);
  const { user, examModeSet } = useAuth();
  const [clickedItemList,setClickedItemList] = useState<Pregunta>();
  const { token, logoutUser} = useAuth();
  useTokenValidation(token, logoutUser);
  
  useEffect(() => {
    if (user) fetchExams(user?.id);
  }, []);

  const updateData = (md: ResponseModule) => {
    console.log("MD", md);
    setModules(md);
  };
  useEffect(() => {
    console.log(module);
  }, [module]);
  const startExam = () => {
    if (user && selectedExam)
      iniciarExamen(user?.id, selectedExam?.id).then(() => {
        setIsExamStarted(true);
        examModeSet(true);
      });
  };
  const loadModuleData = async (ex: Examen) => {
    setLoading(true);
    setSelectedExam(ex);
    try {
      if (user) {
        const exams = await fetchGenerarModules(ex.id, user?.id);
        setModules(exams);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchExams = async (uid: string) => {
    if (!user) return;
    setLoading(true);
    console.log(user);
    try {
      const exams = await fetchExamsService(uid);
      setExams(exams);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };


  if (loading) {
    return <LoadingModal loading={loading} />;
  }
  return (
    <>
      {selectedExam ? (
        <>
          {isExamStarted && module ? (
            <Box
              sx={{
                display: "flex",
                alignContent: "space-between",
                flexDirection: "row",
                width: "100%",
                height: "100vh",
              }}
            >
              {/* Main Content */}
              <Box
                sx={{
                  width: endExam? "100%":"70%",
                }}
              >
                <NewQuestionnaire
                  dataModule={module}
                  exam={selectedExam}
                  updatedata={updateData}
                  clickedQuestion= {clickedItemList}
                  endExam={ setEndExam }
                />
              </Box>

              {/* Sidebar */}
              {!endExam &&(
                  <Box
                  sx={{
                    width: "30%",
                    overflowY: "auto",
                    display: "flex",
                    flexDirection: "column",
                    padding: 0.5,
                    backgroundColor: "#f5f5f5",
                    borderRadius: 5,
                  }}
                >
                
                    <List
                      sx={{
                        height: "100%",
                        overflowY: "auto",
                        overflowX: "hidden",
                        padding: 0,
                      }}
                    >
                      {module?.modulos.map((modulo: any) => (
                        <>
                          {/* Sticky Subheader for Module */}
                          <ListSubheader
                            sx={{
                              backgroundColor: "#f5f5f5",
                              position: "sticky",
                              top: 0,
                              zIndex: 1,
                              borderBottom: "1px solid #ddd",
                              paddingY: 1,
                            }}
                          >
                            <Typography sx={{ fontWeight: "bold", fontSize: 15 }}>
                              {modulo.nombreModulo}
                            </Typography>
                          </ListSubheader>
  
                          {/* Questions for the Module */}
                          {modulo.preguntas.map((pregunta: Pregunta, index: number) => (
                            <ListItem
                            key={`pregunta-${pregunta.id}-${index}`}
                            disablePadding
                            sx={{ width: "auto", height: 35,
                              marginBottom:
                              index === modulo.preguntas.length - 1 ? 2 : 0, // Espacio entre módulos
                             }}
                            >
                              
                              <ListItemButton onClick={() => { setClickedItemList(pregunta)}}>
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    overflowX: "hidden",
                                    width: "100%",
                                  }}
                                >
                                  <Box 
                                  sx={{
                                    width: "90%",
                                    display: "flex",
                                    alignItems: "center",
                                    whiteSpace: "normal",
                                    wordBreak: "break-word",
                                  }}>
                                    <Typography
                                      noWrap
                                      sx={{
                                        overflowX: "hidden",
                                        textOverflow: "ellipsis",
                                        fontSize: 14,
                                        width: 300
                                      }}
                                    >
                                      {pregunta.texto}
                                    </Typography>
                                  </Box>
                                  <Box sx={{ width: "5%" }}>
                                    {pregunta.isAnswered? 
                                      <FactCheckIcon
                                        sx={{
                                          width: "auto",
                                          color: "#4CAF50",
                                          fontSize: 18,
                                          
                                        }}
                                      />
                                     : 
                                     <CropSquareIcon
                                        sx={{
                                          width: "auto",
                                          color: "#9E9E9E",
                                          fontSize: 20,
                                        }}
                                      />}
  
                                  </Box>
                                  <Box sx={{ width: "5%"}}>
                                    {pregunta.isMarked ? (
                                      <FolderSpecialIcon
                                        sx={{
                                          color: "#FF9800",
                                          fontSize: 18,
                                        }}
                                      />
                                    ): 
                                    
                                     <FolderSpecialIcon
                                        sx={{
                                          width: "auto",
                                          color: "#f5f5f5",
                                          fontSize: 20,
                                        }}
                                      />}
                                  
                                  </Box>
                                </Box>
                              </ListItemButton>
                            </ListItem>
                          ))}
                        </>
                      ))}
                    </List>
                </Box>

              )}
            
            </Box>
          ) : (
            <>
              {module?.modulos && (
                <ExamDetail
                  modules={module?.modulos}
                  startExam={startExam}
                  exam={selectedExam}
                />
              )}
            </>
          )}
        </>
      ) : (
        <>
          <CardContent>
            <Typography
              variant="h6"
              fontWeight="bold"
              component="h2"
              gutterBottom
            >
              Exámenes inscritos
            </Typography>
            {examenes.map((examen) => (
              <ExamCard
                key={"card-" + examen.id}
                examen={examen}
                callback={() => loadModuleData(examen)}
                isDetail={true}
              />
            ))}
          </CardContent>
        </>
      )}
    </>
  );
};

export default DashboardExams;
