import React, { useState } from "react";
import {
  Box,
  Typography,
  Button,
  TextField,
  Card,
  CardMedia,
  CardContent,
  InputAdornment,
  OutlinedInput,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Examen1 from "../../assets/img/exam-1.png";

interface ExamInfoBoxProps {
  onSearchChange: (value: string) => void;
  examName: string;
  onEstatusChange?: (value: string) => void;
}

const ExamInfoBox: React.FC<ExamInfoBoxProps> = ({
  onSearchChange,
  examName,
  onEstatusChange
}) => {
  const tableEstatus = [
    "completado" ,"pendiente","en progreso" ,"pausado" ,"detenido"
 ];
 const[ selectedStatus, setSelectedEstatus] = useState<string>("");
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        backgroundColor: "white",
        margin: "0 auto",
      }}
    >
      <CardMedia
        component="img"
        image={Examen1}
        alt="Exam"
        sx={{ width: 80, height: 80, borderRadius: "50%", marginRight: 2 }}
      />
      <CardContent sx={{ flexGrow: 1 }}>
        <Typography variant="h6" fontWeight="bold" color="#1e293b">
          {examName}
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center", gap: 1, mt: 1 }}>
          <FormControl sx={{ m: 1, width: "40%" }}>
            <InputLabel htmlFor="outlined-adornment-search">Buscar</InputLabel>
            <OutlinedInput
              id="outlined-adornment-search"
              placeholder="Buscar por Nombre o Correo"
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon></SearchIcon>
                </InputAdornment>
              }
              label="Amount"
              size="small"
              onChange={(e) => onSearchChange(e.target.value)}
            />
          </FormControl>
          {onEstatusChange && (
          <FormControl sx={{ m: 1, width: "30%" }}>
                <InputLabel id="estatus-select-label">Selecciona un estatus</InputLabel>
                <Select
                  labelId="estatus-select-label"
                  variant="standard"
                  value={selectedStatus}
                  
                  onChange={(e) => { 
                    if(onEstatusChange)
                    onEstatusChange(e.target.value)
                    setSelectedEstatus(e.target.value)
                  }}
                  label="Estatus"
                  size="small"
                >
                   <MenuItem key={"es-em"} value={""}>{"Selecciona un estatus"}</MenuItem>
    
                  {tableEstatus.map((mod) => (
                    <MenuItem key={"es-"+mod} value={mod}>
                      {mod}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
          )}
      
          {/* <TextField
            variant="outlined"
            placeholder="Buscar por participante"
            size="small"
            onChange={(e) => onSearchChange(e.target.value)}
            InputProps={{
              endAdornment: <SearchIcon fontSize="small" />,
            }}
            sx={{ flexGrow: 1 }}
          /> */}
        </Box>
      </CardContent>
    </Box>
  );
};

export default ExamInfoBox;
