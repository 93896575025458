import React, { useState } from "react";
import {
  CardContent,
  TextField,
  Button,
  Typography,
  Box,
  Grid,
  FormControlLabel,
  Checkbox,
  Snackbar,
  Alert,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { createAdmin, createSinodal } from "../../../services/userServices";
import { SinodalCreate } from "../../../interfaces/IUser";
import { useAuth } from "../../../context/AuthContext";

const SinodalesCreate: React.FC = () => {
  const { adminToken} = useAuth();
  const [formData, setFormData] = useState<SinodalCreate>({nombre: "", apellidoMaterno: "", apellidoPaterno: "", email: "",password: ""});
  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    severity: "success" | "error";
  }>({ open: false, message: "", severity: "success" });
  const navigate = useNavigate();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };
  const validateForm = (): boolean => {
    const { nombre, apellidoPaterno,  email, password } = formData;

    if (!nombre || !apellidoPaterno ||  !email  || !password) {
      setSnackbar({
        open: true,
        message: "Por favor, complete todos los campos.",
        severity: "error",
      });
      return false;
    }

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(email)) {
      setSnackbar({
        open: true,
        message: "Por favor, ingrese un correo electrónico válido.",
        severity: "error",
      });
      return false;
    }

    return true;
  };
  const handleSubmit = async () => {
    if (!validateForm()) return;

    try {
      await createSinodal(formData, adminToken?? ""); 
      setSnackbar({
        open: true,
        message: "Sinodal actualizado con éxito.",
        severity: "success",
      });
      setTimeout(() => navigate(-1), 2000);
    } catch (error) {
      console.error("Error al actualizar el sinodal:", error);
      setSnackbar({
        open: true,
        message: "Hubo un error al actualizar el sinodal.",
        severity: "error",
      });
    }
  };

  const handleSnackbarClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <CardContent>

    <Typography sx={{marginBottom: 5}}  variant="h6" fontWeight="bold" component="h2" gutterBottom>
      Crear Sinodal
    </Typography>
    <Box component="form" onSubmit={handleSubmit} noValidate>
      {/* Fields */}
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: 2, // Adds consistent spacing
          marginBottom: 2, // Adds space below the inputs
        }}
      >
        <Box sx={{ flex: "1 1 calc(50% - 16px)" }}> {/* Responsive width */}
          <TextField
            label="Nombre"
            name="nombre"
            fullWidth
            value={formData.nombre}
            onChange={handleChange}
            required
          />
        </Box>
        <Box sx={{ flex: "1 1 calc(50% - 16px)" }}>
          <TextField
            label="Apellido Paterno"
            name="apellidoPaterno"
            fullWidth
            value={formData.apellidoPaterno}
            onChange={handleChange}
            required
          />
        </Box>
        <Box sx={{ flex: "1 1 calc(50% - 16px)" }}>
          <TextField
            label="Apellido Materno"
            name="apellidoMaterno"
            fullWidth
            value={formData.apellidoMaterno}
            onChange={handleChange}
          />
        </Box>
        <Box sx={{ flex: "1 1 calc(50% - 16px)" }}>
          <TextField
            label="Correo"
            name="email"
            fullWidth
            value={formData.email}
            onChange={handleChange}
            required
          />
        </Box>
        <Box sx={{ flex: "1 1 calc(50% - 16px)" }}>
          <TextField
            label="Password"
            name="password"
            fullWidth
            value={formData.password}
            onChange={handleChange}
            required
          />
        </Box>
        <Box sx={{ flex: "1 1 calc(50% - 16px)" }}>
    
        </Box>
      </Box>
  
      {/* Buttons */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: 2,
        }}
      >
        <Button variant="outlined" onClick={() => navigate(-1)}>
          Cancelar
        </Button>
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          Guardar
        </Button>
      </Box>
    </Box>
  
    {/* Snackbar for notifications */}
    <Snackbar
      open={snackbar.open}
      autoHideDuration={4000}
      onClose={handleSnackbarClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
    >
      <Alert
        onClose={handleSnackbarClose}
        severity={snackbar.severity}
        sx={{ width: "100%" }}
      >
        {snackbar.message}
      </Alert>
    </Snackbar>
  </CardContent>
  
  );
};

export default SinodalesCreate;
