import React, { useEffect, useState } from "react";
import {
  Typography,
  Box,
  Paper,
  Divider,
  CircularProgress,
} from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import logo from "../assets/img/logo.png";
import { IValidateDiploma } from "../interfaces/IUser";
import SnackbarAlert from "../components/Snackbar/SnackbarAlert";
import { useSnackbar } from "../components/Snackbar/Snackbar";
import { validateDiploma } from "../services/examServices";

const ValidateDiploma: React.FC = () => {
  const [searchParams] = useSearchParams();
  const idQR = searchParams.get("idQR");
  const navigate = useNavigate();
  const { snackbar, showSnackbar, handleSnackbarClose } = useSnackbar();
  const [dataExam, setDataExam] = useState<IValidateDiploma | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (!idQR) {
      navigate("/");
    } else {
      handleValidate();
    }
  }, []);

  const handleValidate = async () => {
    if (!idQR) return;
    setLoading(true);
    try {
      const response = await validateDiploma(idQR);
      if (response) {
        setDataExam(response);
        showSnackbar("Diploma validado correctamente", "success");
      }
    } catch (err) {
      showSnackbar("Ocurrió algo al validar el diploma.", "error");
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
      sx={{ backgroundColor: "#f4f6f9", padding: 2 }}
    >
      <Paper
        elevation={6}
        sx={{
          padding: 4,
          borderRadius: 3,
          maxWidth: 600,
          textAlign: "center",
          backgroundColor: "#fff",
        }}
      >
        <img src={logo} alt="ExamSys Logo" width={80} style={{ marginBottom: 16 }} />
        <Typography variant="h5" fontWeight="bold" gutterBottom>
          Certificado de autenticidad de contancia
        </Typography>

        {loading ? (
          <CircularProgress sx={{ marginY: 4 }} />
        ) : dataExam ? (
          <>
            <Typography variant="h6" fontWeight="bold" color="primary">
              {dataExam.certificacionNombre}
            </Typography>
            <Divider sx={{ marginY: 2 }} />
            <Typography>
              <strong>Nombre:</strong> {dataExam.nombre} {dataExam.apellidoPaterno} {dataExam.apellidoMaterno}
            </Typography>
            <Typography>
              <strong>Cedula profesional:</strong> {dataExam.cedulaProfesional}
            </Typography>
            <Typography>
            <strong>Fecha de certificación:</strong> {dataExam.fechaUltimoExamen}
            </Typography>
            <Typography>
              <strong>{dataExam.resultado}</strong>
            </Typography>
            
          </>
        ) : (
          <Typography color="error">Constancia invalida</Typography>
        )}
      </Paper>
      <SnackbarAlert
        open={snackbar.open}
        message={snackbar.message}
        severity={snackbar.severity}
        onClose={handleSnackbarClose}
      />
    </Box>
  );
};

export default ValidateDiploma;
