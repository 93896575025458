import React, { useEffect, useState } from 'react';
import { Button, TextField, Typography, Box, Paper, Alert, Link ,  InputAdornment,
  IconButton,} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import background from '../../assets/img/background-admin.png';
import logo from '../../assets/img/logo.png';
import { loginAdminService } from '../../services/authServices';
import { Visibility, VisibilityOff } from "@mui/icons-material";
import LoadingModal from '../../components/Loading/LoadingModal';

const Login: React.FC = () => {
  const navigate = useNavigate();
  const { loginAdmin, admin, user, sinodal } = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  
  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };  
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (admin) {
      navigate("/control");
    }
    if (user) {
      navigate("/dashboard");
    }
    if (sinodal) {
      navigate("/sinodal");
    }
  }, [user, admin, sinodal]);

  const handleLogin = async () => {
    if (!email || !password) {
      setError('Por favor, ingrese su correo electrónico y contraseña');
      return;
    }
    setLoading(true);

    setError(null);

      await loginAdminService(email, password).then( response => {
        if (response && response.token && response.admin) {
          loginAdmin(response.token, response.admin);
          navigate('/control/monitoreo');
        }
      }).catch( error => {
        setError("Usuario y/o contraseña incorrecta.");
        console.error(error);
      }).finally( () => {
        setLoading(false);
      });
      
    
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
      if (event.key === 'Enter') {
        handleLogin();
      }
    };

  return (
    <>
    <Box display="flex" height="100vh" sx={{ backgroundColor: '#1B76BF' }}>
      <Box
        flex={1}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        sx={{ padding: 4, paddingTop: '10%' }}
      >
        <img src={background} alt="Background" style={{ height: '380px', marginBottom: '180px' }} />
      </Box>

      <Box
        flex={1}
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{ width: { xs: '100%', sm: '50%', md: '40%' } }}
      >
        <Paper
          elevation={3}
          sx={{
            paddingTop: '5%',
            paddingLeft: 10,
            paddingRight: 10,
            borderRadius: 2,
            width: '100%',
            maxWidth: 350,
            minHeight: '90vh',
            mx: 2,
            textAlign: 'center',
            backgroundColor: 'white',
            color: '#000000',
          }}
        >
          <img src={logo} width={100} height={100} alt='ExamSys logo' />
          <Typography variant="h5" gutterBottom color="primary">
            Iniciar sesión
          </Typography>
          {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
          <TextField
            fullWidth
            label="Email"
            margin="normal"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onKeyDown={handleKeyDown}
            sx={{
              input: { color: '#000000' },
              '& .MuiInputLabel-root': { color: '#6e6e73' },
              '& .MuiOutlinedInput-root': {
                '& fieldset': { borderColor: 'primary.main' },
                '&:hover fieldset': { borderColor: 'primary.dark' },
              },
            }}
          />
          <TextField
            fullWidth
            label="Password"
            margin="normal"
            type={showPassword ? "text" : "password"}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onKeyDown={handleKeyDown}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label={
                      showPassword ? "Hide password" : "Show password"
                    }
                    onClick={togglePasswordVisibility}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
            <Link href="/adminpassword" variant="body2" underline="hover">
              ¿Olvidaste tu contraseña?
            </Link>
          </Box> 

          <Button
            fullWidth
            variant="contained"
            onClick={handleLogin}
            sx={{
              mt: 1,
              mb: 2,
              backgroundColor: 'primary.main',
              color: '#ffffff',
              '&:hover': { backgroundColor: 'primary.dark' },
            }}
          >
            Ingresar
          </Button>
        </Paper>
      </Box>
    </Box>
    <LoadingModal loading={loading} />

    </>
  );
};

export default Login;
