import axios from "axios";
import { serviceUrl } from "../Constants/constants";

export const getExamCompleteReport = async (examenId: string) => {
  const response = await axios.get(`${serviceUrl}/admin/report/evaluacion/condensado/excel/${examenId}`, {
    responseType: "blob", 
  });
  return response.data;
};
export const getExamPsicometricoReport = async (examenId: string) => {
  const response = await axios.get(`${serviceUrl}/admin/report/evaluacion/psicometrico/excel/${examenId}`, {
    responseType: "blob", 
  });
  return response.data;
};
export const getIndividualReport = async (examStudentId: string) => {
  const response = await axios.get(`${serviceUrl}/admin/report/evaluacion/medico/excel/${examStudentId}`, {
    responseType: "blob", 
  });
  return response.data;
};
export const getDiploma = async (examStudentId: string) => {
  const response = await axios.get(`${serviceUrl}/api/diploma/examen/${examStudentId}`, {
    responseType: "blob", 
  });
  return response.data;
};
export const getExamPracticoReport = async (examenId: string) => {
  const response = await axios.get(`${serviceUrl}/admin/report/evaluacion/practico/porestaciones/excel/${examenId}`, {
    responseType: "blob", 
  });
  return response.data;
};
export const getExamPracticoReportFull = async (examenId: string) => {
  const response = await axios.get(`${serviceUrl}/admin/report/evaluacion/practico/porestacionesfull/excel/${examenId}`, {
    responseType: "blob", 
  });
  return response.data;
};
export const getExamPracticoPsicometrico = async (examenId: string) => {
  const response = await axios.get(`${serviceUrl}/admin/report/evaluacion/practico/psicometrico/excel/${examenId}`, {
    responseType: "blob", 
  });
  return response.data;
};
