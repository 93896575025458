import axios from "axios";
import { Shop , ShopCreate} from "../interfaces/IExam";
import { ExamPayment, IValidateDiploma } from "../interfaces/IUser";
const serviceUrl = "https://backexams-624915669123.us-central1.run.app";

export interface CreateOrder {
  userId: string;
  examenId: string;
}

export interface PayResponse {
  order_id: string;
  payment_url: string;
}


export const getShopList = async (): Promise<Shop[]> => {
    const response = await axios.get(serviceUrl+'/shop/catalog/');
    return response.data;
  };

  export const createVenta =  async (formData: ShopCreate) => {
    const url = serviceUrl+'/shop/catalog/nuevo';
    const response = await axios.post(url,formData,{
      headers: { "Content-Type": "application/json" },
    })
    return response.data;
  }
  export const updateVentas = async (idCatalogo: string, updatedData: any) => {
    const url = serviceUrl+'/shop/catalog/edit/'+idCatalogo;
    const response = await axios.put(url, updatedData, {
      headers: { "Content-Type": "application/json" },
    });
    return response.data;
  };

  export const generarOrden = async (orderData : CreateOrder, token: string) => {
    const url = serviceUrl+'/shop/crear-orden';
    const response = await axios.post(url, orderData, {
      headers: { "Content-Type": "application/json" ,
        Authorization: `Bearer ${token}`, 
      },
    });
    return response.data;
  }

  export const getExamPay = async (id: string,token: string)  => {
    try {
      
      const response = await axios.get<ExamPayment>(serviceUrl+'/shop/confirmar/pago/'+id,
        {
          headers: { "Content-Type": "application/json" ,
            Authorization: `Bearer ${token}`, 
          },}
      ); 
       return response.data;
  
    } catch (error: any) {
      throw new Error(error.response?.data?.error || error.message);

    }
  };