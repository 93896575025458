import React, { useState } from "react";
import { Box, Typography, Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { useFetchData } from "../../hooks/services";
import { EstudiantePractico, Examen } from "../../interfaces/IExam";
import ExamInfoBox from "../Cards/ExamInfoBox";
import LoadingModal from "../Loading/LoadingModal";
import ParticipantsTableSinodal from "./ParticipantsTableSinodal";
import { getStudentsSinodal, iniciarExamenSinodal } from "../../services/examServices";
import { useAuth } from "../../context/AuthContext";

const SinodalSustentantes: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { sinodal, examModeSet } = useAuth();
  const { exam } = location.state; // Recibe los datos del examen desde la navegación
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedSustentante, setSelectedSustentante] = useState<EstudiantePractico | null>(null);
  const [openModal, setOpenModal] = useState(false); // Estado para abrir/cerrar el modal

  const [openModalConfirm, setOpenModalConfirm] = useState(false); // Nuevo modal de confirmación final
  const [confirmationText, setConfirmationText] = useState(""); // Estado del input del usuario

  const [loading, setLoading] = useState(false); // Indicador de carga para iniciar examen

  const { data: sinodales, loading: sinodalesLoading } = useFetchData(() => getStudentsSinodal(exam.id.toString()), []);

  const handleOpenModal = (sustentante: EstudiantePractico) => {
    setSelectedSustentante(sustentante); // Guarda el sustentante seleccionado
    setOpenModal(true); // Abre el modal
  };

  const handleCloseModal = () => {
    setOpenModal(false); // Cierra el modal
    setSelectedSustentante(null);
  };

  const handleConfirmStartExam = async () => {
    if (!selectedSustentante || !sinodal) return;

    setOpenModalConfirm(true); //Se muestra nuevo modal
    setOpenModal(false); //Se cierra modal anterior
    /* setLoading(true);
    try {
      // Inicializa el examen
      await iniciarExamenSinodal(selectedSustentante.id, exam.id, sinodal.id);
      examModeSet(true); // Activa el modo examen
      // Redirige directamente al cuestionario
      navigate("/sinodal/exam", { state: { exam: exam, sustentante: selectedSustentante } });
    } catch (error) {
      console.error("Error al iniciar el examen:", error);
    } finally {
      setLoading(false);
    } */
  };

  const handleCloseConfirmModal = () => {
    setOpenModalConfirm(false);
    setConfirmationText(""); // Limpiar el input
  };
  
  const handleFinalConfirmation = async () => {
    if (!selectedSustentante || !sinodal) return;

    if (confirmationText.toLowerCase() !== "aceptar") return; // Solo continúa si escribió "aceptar"
  
    setLoading(true);
    try {
      await iniciarExamenSinodal(selectedSustentante.id, exam.id, sinodal.id);
      examModeSet(true);
      navigate("/sinodal/exam", { state: { exam: exam, sustentante: selectedSustentante, sinodal: sinodal } });
    } catch (error) {
      console.error("Error al iniciar el examen:", error);
    } finally {
      setLoading(false);
    }
  };

  if (sinodalesLoading) {
    return <LoadingModal loading={sinodalesLoading} />;
  }

  if (!sinodales) {
    return (
      <Box sx={{ mb: 5 }}>
        <ExamInfoBox onSearchChange={setSearchTerm} examName={exam.nombre} />
        <Typography>No se encontraron sustentantes</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ mb: 5 }}>
      <ExamInfoBox onSearchChange={setSearchTerm} examName={exam.nombre} />
      <Box sx={{ maxHeight: "60vh" }}>
        <ParticipantsTableSinodal
          initialEstudiantes={sinodales}
          searchTerm={searchTerm}
          callBackAction={handleOpenModal} // Llama al modal al hacer clic
        />
      </Box>

      {/* Modal de Confirmación */}
      <Dialog open={openModal} onClose={handleCloseModal} maxWidth="sm" fullWidth>
        <DialogTitle sx={{ fontSize: "1.5rem", fontWeight: "bold" }}>Antes de iniciar el examen</DialogTitle>
        <DialogContent>
          <Typography variant="body1" color="textPrimary" >
            <p>Está a punto de iniciar el examen <strong>{exam.nombre}</strong> con una duración de <strong>{exam.tiempoLimite} minutos</strong></p>
            <p>Por favor, siga estas recomendaciones para evitar problemas:</p>
          </Typography>

          <Box mt={2} component="ul" >
            <li>
              <Typography variant="body1" color="textPrimary">
                <strong>No cierre ni refresque esta página:</strong> Si lo hace, el examen podría detenerse y no podrá continuar.
              </Typography>
            </li>
            <li>
              <Typography variant="body1" color="textPrimary">
                <strong>Encuentre un lugar tranquilo:</strong> Asegúrese de no ser interrumpido durante el examen.
              </Typography>
            </li>
            <li>
              <Typography variant="body1" color="textPrimary">
                <strong>Siga las instrucciones cuidadosamente:</strong> Lea con calma cada pregunta antes de responder.
              </Typography>
            </li>
            <li>
              <Typography variant="body1" color="textPrimary">
                <strong>Use el tiempo sabiamente:</strong> El tiempo del examen es limitado, pero suficiente para contestar con calma.
              </Typography>
            </li>
          </Box>

          <Box
            mt={3}
            sx={{
              border: "3px solid", // Marco más destacado
              borderColor: "secondary.main",
              borderRadius: "10px",
              padding: 3,
              textAlign: "center",
              backgroundColor: "rgba(0, 0, 0, 0.05)", // Fondo sutil
            }}
          >
            <Typography
              variant="h6"
              sx={{
                fontWeight: "bold",
                fontSize: "1.3rem", // Tamaño mayor
                color: "secondary.main",
              }}
            >
              El examen comenzará cuando haga clic en "Continuar".
            </Typography>
          </Box>
        </DialogContent>

        <DialogActions sx={{ justifyContent: "space-between", px: 3, pb: 2 }}>
          <Button onClick={handleCloseModal} color="secondary" sx={{ fontSize: "1rem" }}>
            Cancelar
          </Button>
          <Button
            onClick={handleConfirmStartExam}
            variant="contained"
            color="primary"
            disabled={loading}
            sx={{ fontSize: "1rem" }}
          >
            {loading ? "Iniciando..." : "Continuar"}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openModalConfirm} onClose={handleCloseConfirmModal} maxWidth="sm" fullWidth>
        <DialogTitle sx={{ fontSize: "1.5rem", fontWeight: "bold" }}>Confirmación antes de iniciar el examen</DialogTitle>
        <DialogContent>
          <Typography variant="body1" color="textPrimary" >
            <p>Confirma que los siguientes datos sean correctos antes de continuar:</p> 
          </Typography>
          <Box
            sx={{
              mt: 2,
              
              borderRadius: "8px",
              padding: 2,
              backgroundColor: "rgba(238, 238, 250, 0.05)",
            }}
          >
          <Typography variant="body1">
          <strong>Nombre del examen: </strong>{exam.nombre}
          </Typography>
          <Typography variant="body1">
          <strong>Duración: </strong>{exam.tiempoLimite}
          </Typography>
          <Typography variant="body1">
          <strong>Sustentante: </strong>{selectedSustentante?.nombre} {selectedSustentante?.apellidoPaterno} {selectedSustentante?.apellidoMaterno}
          </Typography>
          
            <Typography variant="body1" sx={{ textAlign: "center", fontSize: "1.1rem", mt: 1 }}>
              
            </Typography>
          </Box>

          <Box
            mt={3}
            sx={{
              border: "3px solid",
              borderColor: "secondary.main",
              borderRadius: "10px",
              padding: 3,
              textAlign: "center",
              backgroundColor: "rgba(0, 0, 0, 0.05)",
            }}
          >
            <Typography
              variant="h6"
              sx={{
                fontWeight: "bold",
                fontSize: "1.3rem",
                color: "secondary.main",
              }}
            >
              Para confirmar, escriba la palabra "aceptar"
            </Typography>
            <TextField
              fullWidth
              margin="normal"
              variant="outlined"
              label="Escriba 'aceptar' para continuar"
              value={confirmationText}
              onChange={(e) => setConfirmationText(e.target.value)}
            />
          </Box>
        </DialogContent>

        <DialogActions sx={{ justifyContent: "space-between", px: 3, pb: 2 }}>
          <Button onClick={handleCloseConfirmModal} color="secondary">
            Cancelar
          </Button>
          <Button
            onClick={handleFinalConfirmation}
            variant="contained"
            color="primary"
            disabled={confirmationText.toLowerCase() !== "aceptar"}
          >
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default SinodalSustentantes;
