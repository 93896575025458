import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  Box,
  Typography,
  LinearProgress,
  Button,
  Alert,
  CardContent,
  Card,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Stack, 
  DialogContentText, 
  TextField
} from "@mui/material";

import Question from "./Question";

import { Examen, Modulo, Pregunta, ResponseModule } from "../../interfaces/IExam";
import {
  guardarRespuestasExamen,
  terminarExamen,
} from "../../services/examServices";
import { useAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { logoutService } from "../../services/authServices";
import PausedDialog from "../Dialog/PausedDialog";
import { formatSeconds } from "../../utils/utils";
import useEventSource from "../../hooks/useEventSource";

interface esPractico {
  sinodalId?: string;
  sustentante?: string;
  intento?: string;
}
interface NewQuestionnaireProps {
  dataModule: ResponseModule;
  exam: Examen;
  updatedata: (data: ResponseModule) => void;
  clickedQuestion?: Pregunta;
  endExam: (value: boolean) => void;
  esPractico?: esPractico;

}

const NewQuestionnaire: React.FC<NewQuestionnaireProps> = ({
  dataModule,
  exam,
  updatedata,
  clickedQuestion,
  endExam,
  esPractico
}) => {
  const examModules = dataModule.modulos;
  const [moduleIndex, setModuleIndex] = useState(0);
  const [questionIndex, setQuestionIndex] = useState(0);
  const [currentAnswer, setCurrentAnswer] = useState<string | undefined>();
  const [answers, setAnswers] = useState< { preguntaId: string; opcionId: string }[]>([]);
  const [showResult, setShowResult] = useState(false);
  const [showModuleEnd, setShowModuleEnd] = useState(false);
  const [lastModule, setLastModule] = useState(false);
  const [notFinished, setNotFinished] = useState(false);
  const [loading, setLoading] = useState(false);
  const { user, examModeSet } = useAuth();
  const [marcadas, setMarcadas] = useState<Pregunta[]>([]);
  const [confirmationText, setConfirmationText] = useState<string>("");
  const [progress, setProgress] = useState<number>(0);

  const [examStatus, setExamStatus] = useState("");
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  useEffect( () => {

    
    const newDataModule = {...dataModule};
     console.log(clickedQuestion?.id+"ID ------");

     const mdIndex = newDataModule.modulos.findIndex((modulo) =>
      modulo.preguntas.some((pregunta) => pregunta.id === clickedQuestion?.id)
    );
    if (mdIndex !== -1) {
        const preguntaIndex = newDataModule.modulos[mdIndex].preguntas.findIndex(
          (pr) => pr.id === clickedQuestion?.id
        );
        if (preguntaIndex !== -1) {
          setModuleIndex(mdIndex);
          setQuestionIndex(preguntaIndex);
          //questionIndex
        }
    }
    // newDataModule.modulos.findIndex( fi => fi.)
    // if(clickedQuestion && clickedQuestion.moduloId){
    // setModuleIndex(clickedQuestion?.moduloId);
    // setQuestionIndex(clickedQuestion?.id);
    // }
  },[clickedQuestion])
  //    updateQuestion(questionIndex, newDataModule.modulos[mdIndex]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [markedCount, setMarkedCount] = useState(0);
  const [unansweredCount, setUnansweredCount] = useState(0);
  const pollingRef = useRef<NodeJS.Timeout | null>(null); // Track polling

  const updateProgressLine = () => {
    let answered = 0;
    let totalQuestions = 0;

    dataModule.modulos.forEach((modulo) => {
      modulo.preguntas.forEach((pregunta) => {
        if(pregunta.isAnswered) answered +=1;
        totalQuestions++;
      });
    });
    setProgress((answered/totalQuestions) * 100);
  //  let progress = (currentQuestionNumber / totalQuestions) * 100;

  }
  const calculateTotals = () => {
    let marked = 0;
    let answered = 0;
    let totalQuestions = 0;
      dataModule.modulos.forEach((modulo) => {
        modulo.preguntas.forEach((pregunta) => {
          if (pregunta.isMarked) marked += 1;
          if(pregunta.isAnswered) answered +=1;
          totalQuestions++;
        });
      });

      
      setIsDialogOpen(true);

    // if(marked > 0){
    //   setIsDialogOpen(true);
    // }else{
    //   handleExamEnd("manual")
    // }
    // if(unanswered > 0){
    //   console.log(unanswered+" - ")
    // }
    setMarkedCount(marked);
    setUnansweredCount(totalQuestions - answered);
  };
  // const handleCheckAndOpenDialog = () => {
  //   calculateTotals();
  //   const hasMarkedOrUnanswered =
  //     markedCount > 0 || unansweredCount > 0; 
  //   if (hasMarkedOrUnanswered) {
  //     setIsDialogOpen(true);
  //   }
  // };
  useEffect(() => {
    updateQuestion(questionIndex, examModules[moduleIndex]);
    updateProgressLine();
  }, [questionIndex]);

  // const fetchStatus = async () => {
  //   try {
  //     const response = await fetch(
  //       "https://backexams-624915669123.us-central1.run.app/api/monitor/examenUsuario/" +
  //         dataModule.examenUsuarioId
  //     );
  //     if (!response.ok) {
  //       throw new Error("Failed to fetch status");
  //     }

  //     const data = await response.json();
  //     const newStatus = data.estatus;
  //     // console.log(`Fetched new status: ${newStatus}`);
  //     setExamStatus(newStatus);
  //   } catch (error) {
  //     console.error("Error fetching status:", error);
  //   } finally {
  //     //setTimeout(fetchStatus, 3000); // Long polling
  //   }
  // };
  interface IEstatusExam {
    comando: string;
    examenUsuarioId: string;
  }
  const { data, error } = useEventSource<IEstatusExam>("https://backexams-624915669123.us-central1.run.app/monitor/escuchar/"+dataModule.examenUsuarioId);

    useEffect(() => {
      //pause en_progreso  detenido
      console.log("EXAMEN "+dataModule.examenUsuarioId)
      console.log(data?.comando," DATA PATA RATA")
      if(data?.comando === 'cerrar'){
        setExamStatus("detenido")
      }else if(data?.comando === 'pausar'){
        setExamStatus("pausado")
      }else if(data?.comando === 'continuar'){
        setExamStatus("en_progreso")
      }

      //{"examenUsuarioId":"f5979bc9-f1cc-4ea6-9202-f6fc3c7a4c6c","comando":"cerrar"}  DATA PATA RATA

      // fetchStatus();

      // return () => {
      //   if (pollingRef.current) {
      //     clearTimeout(pollingRef.current); // Stop polling on unmount
      //     console.log("Component unmounted. Polling stopped.");
      //   }
      // };
    }, [data]);

  const examStatusRef = useRef(examStatus);
  const startTimeRef = useRef(Date.now());

  useEffect(() => {
    console.log(`Exam status updated to: ${examStatus}`);
    if (examStatus === "pausado") {
      if(esPractico){
        saveAnswersPractico("pause");
      }else{
        saveAnswers("pause");
      }
      setDialogOpen(true);
    } else if (examStatus === "en_progreso") {
      timeLeftRef.current = displayedTime;
      setDialogOpen(false);
      startTimeRef.current = Date.now();
      countdown();
    } else if (examStatus === "detenido") {
      setDialogOpen(false);
      handleExamEnd("timeout");
    }
  }, [examStatus]);
  const totalSeconds = exam.tiempoLimite * 60;
  const timeLeftRef = useRef<number>(totalSeconds);
  const [displayedTime, setDisplayedTime] = useState<number>(0);

  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const currentModule = examModules[moduleIndex];
  const currentQuestion = currentModule.preguntas[questionIndex];
  const totalQuestions = examModules.reduce(
    (total, module) => total + module.preguntas.length,
    0
  );
  const currentQuestionNumber =
    examModules
      .slice(0, moduleIndex)
      .reduce((total, module) => total + module.preguntas.length, 0) +
    questionIndex;


  const handleExamEnd = (status: string) => {
    
    // if(!checkConditions()){
    //   setIsDialogOpen(true);
    //   return
    // }
    endExam(true);
    setNotFinished(true);
    setExamStatus(status);
    setShowResult(true);
    
    if(esPractico){
      saveAnswersPractico("finish");
    }else{
      saveAnswers("finish");
    }

  };

  useEffect(() => {
    examStatusRef.current = examStatus;
  }, [examStatus]);

  const countdown = useCallback(() => {
    const updateTimer = () => {
      if (showResult) {
        return;
      }

      if (examStatusRef.current === "pausado") {
        console.log("ENTER PAUSADO - estatus");
        return;
      }
      const elapsed = Math.floor((Date.now() - startTimeRef.current) / 1000); // Adjusted for pauses
      const remaining = timeLeftRef.current - elapsed;

      if (remaining <= 0) {
        setDisplayedTime(0);
        handleExamEnd("timeout");
        return;
      }

      setDisplayedTime(remaining);
      requestAnimationFrame(updateTimer);
    };

    requestAnimationFrame(updateTimer);
  }, [totalSeconds, showResult]);

  useEffect(() => {
    countdown();
  }, [countdown]);

  const handleAnswer = (answer: string) => {
    const opcionId = currentQuestion.opciones.find(
      (opt) => opt.texto === answer
    )?.id;

    if (opcionId) {
      setAnswers((prev) => {
        const existingAnswerIndex = prev.findIndex(
          (ans) => ans.preguntaId === currentQuestion.id
        );
        if (existingAnswerIndex > -1) {
          const updatedAnswers = [...prev];
          updatedAnswers[existingAnswerIndex] = {
            preguntaId: currentQuestion.id,
            opcionId,
          };
          return updatedAnswers;
        } else {
          return [...prev, { preguntaId: currentQuestion.id, opcionId }];
        }
      });
    }
    setCurrentAnswer(answer);
  };
  const validateStatusExam = () => {
    if (questionIndex < currentModule.preguntas.length - 1) {
      setQuestionIndex(questionIndex + 1);
      if ((currentQuestionNumber + 1) % 2 === 0) {
        console.log("SAVE ENTER HERE")

        if(esPractico){
          saveAnswersPractico("progress");
        }else{
          saveAnswers("progress");
        }
      }
    } else {
      if (moduleIndex < examModules.length - 1) {
        if (esPractico)
          handleNextModule();
        else
          setShowModuleEnd(true); //no se muestre fin del modulo
      } else {
        setShowModuleEnd(true);
        setLastModule(true);
      }
    }
  };
  const limpiarRespuesta = () => {
      const idToClean = dataModule.modulos[moduleIndex].preguntas[questionIndex].id;
      console.log(idToClean,"ID TO CLEAN");
      
      const answerToDelete = answers.find( ans => ans.preguntaId === idToClean);
      if(answerToDelete){
      const idAns = answers.indexOf(answerToDelete);
      answers.splice(idAns, 1);
    }
  };
  const getIfIsAnswered = () => {
    const idQuestion = dataModule.modulos[moduleIndex].preguntas[questionIndex].id;
    
    const answer = answers.find( ans => ans.preguntaId === idQuestion);
    if(answer)
      return true
    else 
      return false
};
  const handleMark = (mark:boolean) => {
    //validateStatusExam();
    // limpiarRespuesta();

    var newData = { ...dataModule };
 
    const questionToAdd = newData.modulos[moduleIndex].preguntas[questionIndex];

    setMarcadas((prevMarcadas) => {
      const exists = prevMarcadas.some((question) => question.id === questionToAdd.id);
  
      if (!exists) {
        return [...prevMarcadas, questionToAdd];
      }
  
      return prevMarcadas;
    });
    
    newData.modulos[moduleIndex].preguntas[questionIndex].isAnswered = getIfIsAnswered();
    newData.modulos[moduleIndex].preguntas[questionIndex].isMarked = mark;
    updatedata(newData);
  };

  const updateQuestion = (nextQuestion: number, module: Modulo) => {
    console.log(module.preguntas[nextQuestion],"NEXT QUESTION")
    if (module.preguntas[nextQuestion]) {
      const nextAnswer = answers.find(
        (ans) => {
          console.log(ans.preguntaId+" - "+module.preguntas[nextQuestion].id )
          return ans.preguntaId === module.preguntas[nextQuestion].id}
      );
      console.log(module.preguntas[nextQuestion],"ALL MODULE")
      console.log(nextAnswer,"NEXT")
      setCurrentAnswer(
        nextAnswer
          ? module.preguntas[nextQuestion].opciones.find(
              (opt) => opt.id === nextAnswer.opcionId
            )?.texto
          : undefined
      );
    }
  };
  const handleNext = (skip?: boolean) => {
    validateStatusExam();
    const nextQuestion = questionIndex + 1;
    updateQuestion(nextQuestion, currentModule);

    var newData = { ...dataModule };
    if(skip){
      limpiarRespuesta();
    }
    // newData.modulos[moduleIndex].preguntas[questionIndex].isMarked = false;
    newData.modulos[moduleIndex].preguntas[questionIndex].isAnswered = skip
      ? false
      : true;
    updatedata(newData);
  };

  const handleBack = () => {
    if (questionIndex > 0) {
      // Retrocede a la pregunta anterior dentro del módulo actual
      const lastQuestion = questionIndex - 1;
      setQuestionIndex(lastQuestion);

      const previousAnswer = answers.find(
        (ans) => ans.preguntaId === currentModule.preguntas[lastQuestion].id
      );
      setCurrentAnswer(
        previousAnswer
          ? currentModule.preguntas[lastQuestion].opciones.find(
              (opt) => opt.id === previousAnswer.opcionId
            )?.texto
          : undefined
      );
    } else if (moduleIndex > 0) {
      // Si está en la primera pregunta de un módulo que no es el primero
      const prevModuleIndex = moduleIndex - 1;
      const prevModule = examModules[prevModuleIndex];
      const lastQuestionIndex = prevModule.preguntas.length - 1;
      // Cambiar al módulo anterior y cargar la última pregunta
      setModuleIndex(prevModuleIndex);
      setQuestionIndex(lastQuestionIndex);

      const previousAnswer = answers.find(
        (ans) => ans.preguntaId === prevModule.preguntas[lastQuestionIndex].id
      );
      setCurrentAnswer(
        previousAnswer
          ? prevModule.preguntas[lastQuestionIndex].opciones.find(
              (opt) => opt.id === previousAnswer.opcionId
            )?.texto
          : undefined
      );
    }
    // Si está en la primera pregunta del examen (primer módulo, primera pregunta), no hace nada
  };

  type estatusSave = "finish" | "pause" | "progress";
  const saveAnswers = (estatus: estatusSave) => {
    const examResult = {
      respuestas: answers,
      estatus:
        estatus === "progress"
          ? "en_progreso"
          : estatus === "pause"
            ? "pausado"
            : "completado",
      tiempoRestante: displayedTime,
    };
    console.log(examResult);
    if (user)
      guardarRespuestasExamen(user.id, exam.id, examResult).then(() => {
        if (estatus === "finish") {
          terminarExamen(user.id, exam.id).finally(() => setLoading(false));
          examModeSet(false);
        } else setLoading(false);
      });
  };
  const saveAnswersPractico = (estatus: estatusSave) => {
    const examResult = {
      respuestas: answers,
      estatus:
        estatus === "progress"
          ? "en_progreso"
          : estatus === "pause"
            ? "pausado"
            : "completado",
      tiempoRestante: displayedTime,
      sinodalId: esPractico?.sinodalId,
      intento: esPractico?.intento
    };
    console.log(examResult);
    if (esPractico?.sustentante)
      guardarRespuestasExamen(esPractico.sustentante, exam.id, examResult).then(() => {
        if (estatus === "finish" && esPractico.sustentante) {
          terminarExamen(esPractico.sustentante, exam.id).finally(() => setLoading(false));
          examModeSet(false);
        } else setLoading(false);
      });
  };
  const handleNextModule = () => {
    if(esPractico){
      saveAnswersPractico("progress");
    }else{
      saveAnswers("progress");
    }
    
    setModuleIndex(moduleIndex + 1);
    setQuestionIndex(0);
    setShowModuleEnd(false);
  };

  const submitExam = useCallback(() => {
   
    const examResult = {
      respuestas: answers,
      estatus: "en_progreso",
      tiempoRestante: 0,
    };
    if (user) {
      setLoading(true);

      guardarRespuestasExamen(user.id, exam.id, examResult).then(() => {
        terminarExamen(user.id, exam.id).finally(() => setLoading(false));
      });
      //logoutService(user.id);

      console.log("RESULT", examResult);
    }
  }, [answers, exam.id, examStatus]);

  const submitExamPractico = useCallback(() => {
   
    const examResult = {
      respuestas: answers,
      estatus: "en_progreso",
      tiempoRestante: 0,
      sinodalId: esPractico?.sinodalId,
      intento: esPractico?.intento

    };
    if (esPractico?.sustentante) {
      setLoading(true);

      guardarRespuestasExamen(esPractico?.sustentante, exam.id, examResult).then(() => {
        if(esPractico?.sustentante)
        terminarExamen(esPractico?.sustentante, exam.id).finally(() => setLoading(false));
      });
      //logoutService(user.id);

      console.log("RESULT", examResult);
    }
  }, [answers, exam.id, examStatus]);

  useEffect(() => {
    if (showResult) {
      if(esPractico){
        submitExamPractico();
      }else{
        submitExam();
      }
    }
  }, [showResult, submitExam, submitExamPractico]);
  const navigate = useNavigate();

  const goToHome = () => {
    console.log("!2");
    examModeSet(false);
    navigate("/dashboard");
  }

  
  if (showResult) {
    if (notFinished) {
      return (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="50vh"
        >
          <Card
            sx={{
              maxWidth: 500,
              padding: 2,
              textAlign: "center",
              boxShadow: 3,
            }}
          >
            <CardContent>
              <Typography
                variant="h5"
                fontWeight="bold"
                color="primary"
                gutterBottom
              >
                ¡Ha finalizado el examen!
              </Typography>
              Se completó el examen 
              <Typography variant="h6" gutterBottom>
                <strong>{exam.nombre}</strong>
              </Typography>
              <Typography variant="body1" color="textSecondary">
                La evaluación del examen para el sustentante se está revisando. Le mantendremos
                informado con los resultados.
              </Typography>
              <Button
                variant="contained"
                sx={{ mt: 3 }}
                color="secondary"
                onClick={goToHome}
              >
                {" "}
                Salir{" "}
              </Button>
            </CardContent>
            {loading && <LinearProgress sx={{ mt: 2 }} />}
          </Card>
        </Box>
      );
    } else {
      return (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="50vh"
        >
          <Card
            sx={{
              maxWidth: 500,
              padding: 2,
              textAlign: "center",
              boxShadow: 3,
            }}
          >
            <CardContent>
              <Typography
                variant="h5"
                fontWeight="bold"
                color="primary"
                gutterBottom
              >
                ¡Bien hecho!
              </Typography>
              <Typography variant="h6" gutterBottom>
                Terminaste el examen de {exam.nombre}
              </Typography>
              <Typography variant="body1" color="textSecondary">
                Tu evaluación se está revisando. Te mantendremos informado con
                los resultados.
              </Typography>
              <Button
                variant="contained"
                sx={{ mt: 3 }}
                color="secondary"
                onClick={goToHome}
              >
                {" "}
                Salir{" "}
              </Button>
            </CardContent>
            {loading && <LinearProgress sx={{ mt: 2 }} />}
          </Card>
        </Box>
      );
    }
  }

  const handleCloseDialog = () => {
    // timeLeftRef.current = displayedTime;
    setDialogOpen(false);
  };

  return (
    <>
      <Box sx={{ flexGrow: 1, p: 3 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: 2,
            backgroundColor: "#f5f5f5",
            borderRadius: 2,
          }}
        >
          <Typography sx={{ fontWeight: "bold" }}>{exam.nombre}</Typography>
          <Typography variant="body2" color="textSecondary">
            Módulo: {currentModule.nombreModulo}
          </Typography>
          <Button
            color="secondary"
            variant="outlined"
            sx={{
              marginRight: 2,
              borderColor: "#d3d3d3",
              textTransform: "none",
            }}
            onClick={calculateTotals}
          >
            Terminar examen
          </Button>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 2,
            padding: 2,
            borderRadius: 2,
          }}
        >
          <Box
            sx={{ display: "flex", alignItems: "center", flexGrow: 1, mx: 2 }}
          >
            <LinearProgress
              variant="determinate"
              value={lastModule ? 100 : progress}
              sx={{
                flexGrow: 1,
                height: 8,
                borderRadius: 5,
                backgroundColor: "#e0e0e0",
                marginRight: 2,
              }}
            />
            <Typography variant="body1" sx={{ minWidth: 40 }}>
              {lastModule ? 100 : Math.round(progress)}%
            </Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            
            <Typography variant="h6" color="primary" fontWeight={'bold'}>
              {formatSeconds(displayedTime) + " hrs"}
            </Typography>
          </Box>
        </Box>

        {showModuleEnd ? (
          <Box sx={{ textAlign: "center" }}>
            <Typography variant="h6" color="primary" sx={{ marginBottom: 2 }}>
              {lastModule
                ? "Terminaste el módulo y el examen "
                : "Terminaste el módulo"}
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: 2 }}>
              {lastModule
                ? "¿Deseas terminar el exámen?"
                : "¿Deseas avanzar al siguiente módulo?"}
            </Typography>

            <Alert
              severity="error"
              sx={{ maxWidth: 400, margin: "0 auto", mt: 3 }}
            >
              {lastModule
                ? "Una vez que finalices el examen ya no podrás editarlo"
                : "Recomendamos haber contestado todas las preguntas antes de avanzar con el siguiente módulo"}
            </Alert>
            <Button
              variant="outlined"
              color="secondary"
              sx={{ maxWidth: 400, margin: "0 auto", mt: 3, marginRight: 2 }}
              onClick={() => {
                setShowModuleEnd(false);
                setLastModule(false);
              }}
            >
              Regresar
            </Button>
            {lastModule ? (
              <Button
                variant="contained"
                color="primary"
                sx={{ maxWidth: 400, margin: "0 auto", mt: 3, marginRight: 2 }}
                onClick={() => {
                  calculateTotals()
                }}
              >
                Terminar examen
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                sx={{ maxWidth: 400, margin: "0 auto", mt: 3, marginRight: 2 }}
                onClick={handleNextModule}
              >
                Continuar
              </Button>
            )}
          </Box>
        ) : (
          <Box>
            <Question
              question={currentQuestion}
              onAnswer={handleAnswer}
              onNext={handleNext}
              onBack={handleBack}
              onMark={handleMark}
              currentAnswer={currentAnswer}
            />
             {/* {answers.map( ans =>{
              return <Typography>{ans.preguntaId+" "+ans.opcionId}</Typography>
            })}
            {marcadas.map( ans =>{
              return <Typography>{ans.texto+" KIMI"}</Typography>
            })} */}
          </Box>
        )}
      </Box>
      <PausedDialog open={dialogOpen} onClose={handleCloseDialog} />


<Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)}>
  <DialogTitle>
    <Stack direction="row" alignItems="center" spacing={1}>
      
      <Typography variant="h6" fontWeight="bold">
        Terminar examen
      </Typography>
    </Stack>
  </DialogTitle>

  <DialogContent>
    {markedCount > 0 && (
      <DialogContentText
      dangerouslySetInnerHTML={{
        __html: `Tienes marcada${markedCount === 1 ? "" : "s"} <strong><u>${markedCount} pregunta${markedCount === 1 ? "" : "s"}</u></strong> en el examen.`,
      }}
      />
    )}
    {unansweredCount > 0 && (
      <DialogContentText
      dangerouslySetInnerHTML={{
        __html: `Tienes sin responder <strong><u>${unansweredCount} pregunta${unansweredCount === 1 ? "" : "s"}</u></strong> en el examen.`,
      }}
      />
    )}
    <DialogContentText>¿Estás seguro que deseas terminar?</DialogContentText>
    <TextField
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  label="Escribe 'aceptar' para confirmar"
                  value={confirmationText}
                  onChange={(e) => setConfirmationText(e.target.value)}
                />
  </DialogContent>

  <DialogActions>
    <Stack direction="row" spacing={2} justifyContent="space-between" width="100%">
      <Button   onClick={() => {
          setIsDialogOpen(false)
          setConfirmationText("");
      }} color="secondary">
        Continuar el examen
      </Button>
      <Button
        variant="contained"
        onClick={() =>{
          handleExamEnd("completed");
       
        }}
        color="warning"
        disabled={confirmationText.toLowerCase() !== "aceptar"}
        sx={{ fontWeight: "bold" }}
      >
        Terminar examen
      </Button>
    </Stack>
  </DialogActions>
</Dialog>

    </>
  );
};

export default NewQuestionnaire;
