import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
  Typography,
  Box,
  Paper,
  Link,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  InputAdornment,
  IconButton,
  InputLabel,
  Select,
  FormControl,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import logo from "../assets/img/logo.png";
import { registerService, requestRecoverPassword } from "../services/authServices";
import { IRecover, IRegister } from "../interfaces/IUser";
import SnackbarAlert from "../components/Snackbar/SnackbarAlert";
import { useSnackbar } from "../components/Snackbar/Snackbar";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import LoadingModal from "../components/Loading/LoadingModal";
import { estadosMexico } from "../Constants/constants";

const RecoverPassword: React.FC = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const tipo = searchParams.get('tipo');

  
  const navigate = useNavigate();
  useEffect( () => {

    console.log(token,"TOKEN")
    if(!token){
      navigate(tipo && tipo === "A"? "/admin":"/");
    }

  },[])
  
  const { snackbar, showSnackbar, handleSnackbarClose } = useSnackbar();
  const [showPassword, setShowPassword] = useState({
    password: false,
    confirmPassword: false,
  });
  const [loading, setLoading] = useState<boolean>(false);

  const togglePasswordVisibility = (field: "password" | "confirmPassword") => {
    setShowPassword((prev) => ({ ...prev, [field]: !prev[field] }));
  };
  const [formData, setFormData] = useState<IRecover>({
    password: "",
    confirmPassword: "",
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const validateForm = (): boolean => {
    const {
      password,
      confirmPassword,
    } = formData;

    if (
      !password ||
      !confirmPassword
    ) {
      showSnackbar(`Por favor, complete todos los campos`, "error");
      return false;
    }

    if (password !== confirmPassword) {
      showSnackbar(`Las contraseñas no coinciden`, "error");
      return false;
    }
    return true;
  };

  const handleRecover = async () => {
    if (!validateForm()) return;
    if(!token) return;
    setLoading(true);
    try {
      const response = await requestRecoverPassword(token, formData.password);
      if (
        response
         //&& response.message === "Usuario creado e inscrito exitosamente."
      ) {
        showSnackbar(
          `Se actualizo tu contraseña correctamente. Ahora intenta iniciar sesión.`,
          "success"
        );
        setTimeout(() => navigate(tipo && tipo === "A"? "/admin":"/"), 2000); // Redirect to login after 2 seconds
      }
      setLoading(false);
    } catch (err) {
      showSnackbar(
        `No se pudo recuperar la contraseña. Inténtelo de nuevo.`,
        "error"
      );
      console.error(err);
      setLoading(false);
    }
  };

  return (
    <Box display="flex" height="100vh">
      <Box
        flex={1}
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{
          backgroundColor: "#f5f7fa",
          width: { xs: "100%", sm: "50%", md: "40%" },
        }}
      >
        <Paper
          elevation={1}
          sx={{
            paddingTop: "5%",
            paddingLeft: 10,
            paddingRight: 10,
            borderRadius: 2,
            pb: 5,
            width: "100%",
            maxWidth: "60%",
            minHeight: "80vh",
            maxHeight: "80vh",
            mx: 2,
            overflowY: "scroll",
            textAlign: "center",
          }}
        >
          <img src={logo} width={100} height={100} alt="ExamSys logo" />
          <Typography variant="h6" fontWeight={"bold"} gutterBottom>
            Actualizar password
          </Typography>

         
          <TextField
            fullWidth
            label="Password"
            margin="normal"
            type={showPassword.password ? "text" : "password"}
            name="password"
            value={formData.password}
            onChange={handleChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => togglePasswordVisibility("password")}
                  >
                    {showPassword.password ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <TextField
            fullWidth
            label="Confirmar Password"
            margin="normal"
            type={showPassword.confirmPassword ? "text" : "password"}
            name="confirmPassword"
            value={formData.confirmPassword}
            onChange={handleChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => togglePasswordVisibility("confirmPassword")}
                  >
                    {showPassword.confirmPassword ? (
                      <VisibilityOff />
                    ) : (
                      <Visibility />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Button
            fullWidth
            variant="contained"
            onClick={handleRecover}
            sx={{
              mt: 1,
              mb: 2,
              backgroundColor: "primary",
              color: "white",
              "&:hover": { backgroundColor: "secondary" },
            }}
          >
            Cambiar contraseña
          </Button>
          <Typography variant="body2" color="textSecondary">
            Regresar a{" "}
            <Link href="/" variant="body2" underline="hover">
              Iniciar sesión
            </Link>
          </Typography>
        </Paper>
      </Box>

      <LoadingModal loading={loading} />
      <SnackbarAlert
        open={snackbar.open}
        message={snackbar.message}
        severity={snackbar.severity}
        onClose={handleSnackbarClose}
      />
    </Box>
  );
};

export default RecoverPassword;
