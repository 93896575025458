import axios from "axios";
import { Admin, AdminCreate, IRegister, Sinodal, SinodalCreate, User } from "../interfaces/IUser";
import { serviceUrl } from "../Constants/constants";

export const getSustentantesService = async (): Promise<User[]> => {
    const response = await axios.get(serviceUrl+'/admin/user/');
    return response.data;
  };

  export const updateSustentante = async (idExamen: string, updatedData: any) => {
    console.log(updatedData)
    const url = serviceUrl+'/admin/user/editar/'+idExamen;
    const response = await axios.patch(url, updatedData, {
      headers: { "Content-Type": "application/json" },
    });
    return response.data;
  };

  export const getAdminService = async (): Promise<Admin[]> => {
    const response = await axios.get(serviceUrl+'/admin/user/admin');
    return response.data;
  };

  export const updateAdmin = async (idExamen: string, updatedData: any) => {
    const url = serviceUrl+'/admin/'+idExamen;
    const response = await axios.patch(url, updatedData, {
      headers: { "Content-Type": "application/json" },
    });
    return response.data;
  };

  export const createAdmin =  async (formData: AdminCreate) => {
    const url = serviceUrl+'/admin/auth/crear';
    const response = await axios.post(url,formData,{
      headers: { "Content-Type": "application/json" },

    })
    return response.data;
  }

  //--------------------SINODALES//--------------------//
  export const getSinodalService = async (token: string): Promise<Sinodal[]> => {
    const response = await axios.get(serviceUrl + '/admin/sinodal/', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });    return response.data;
  };

  export const updateSinodal = async (idExamen: string, updatedData: any,token: string) => {
    const url = serviceUrl+'/admin/sinodal/edit/'+idExamen;
    const response = await axios.patch(url, updatedData, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}`},
    });
    return response.data;
  };

  export const createSinodal =  async (formData: SinodalCreate,token: string) => {
    const url = serviceUrl+'/admin/sinodal/nuevo';
    const response = await axios.post(url,formData,{
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` },
    })
    return response.data;
  }
  export const deleteSinodal = async (idExamen: string,token: string) => {
    const url = serviceUrl+'/admin/sinodal/delete/'+idExamen;
    const response = await axios.delete(url, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}`},
    });
    return response.data;
  };
  