import React, { useState } from "react";
import {
  CardContent,
  TextField,
  Button,
  Typography,
  Box,
  Grid,
  FormControlLabel,
  Checkbox,
  Alert,
  InputLabel,
  Select,
  FormControl,
  SelectChangeEvent,
  MenuItem,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { IRegister, User } from "../../../interfaces/IUser";

import { estadosMexico } from "../../../Constants/constants";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import LoadingModal from "../../Loading/LoadingModal";
import { useSnackbar } from "../../Snackbar/Snackbar";
import SnackbarAlert from "../../Snackbar/SnackbarAlert";
import { registerService } from "../../../services/authServices";

const SustentanteCrear: React.FC = () => {
  const { state } = useLocation();
  const [loading, setLoading] = useState<boolean>(false);
  const { snackbar, showSnackbar, handleSnackbarClose } = useSnackbar();
  const [showPassword, setShowPassword] = useState({
    password: false,
    confirmPassword: false,
  });
  const togglePasswordVisibility = (field: "password" | "confirmPassword") => {
    setShowPassword((prev) => ({ ...prev, [field]: !prev[field] }));
  };
  const [formData, setFormData] = useState<IRegister>({
    nombre: "",
    apellidoPaterno: "",
    apellidoMaterno: "",
    celular: "",
    cedulaProfesional: "",
    universidad: "",
    email: "",
    estadoOrigen: "",
    password: "",
    confirmPassword: "",
  });
  const validateForm = (): boolean => {
    const {
      nombre,
      apellidoPaterno,
      apellidoMaterno,
      celular,
      cedulaProfesional,
      universidad,
      email,
      estadoOrigen,
      password,
      confirmPassword,
    } = formData;

    if (
      !nombre ||
      !apellidoPaterno ||
      !apellidoMaterno ||
      !celular ||
      !cedulaProfesional ||
      !universidad ||
      !email ||
      !estadoOrigen ||
      !password ||
      !confirmPassword
    ) {
      showSnackbar(`Por favor, complete todos los campos`, "error");
      return false;
    }

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(email)) {
      showSnackbar(`Por favor, ingrese un correo electrónico válido`, "error");
      return false;
    }

    const celularPattern = /^[0-9]*$/;
    if (!celularPattern.test(celular)) {
      showSnackbar(
        `El número de celular no debe de tener letras o caracteres`,
        "error"
      );
      return false;
    }

    if (password !== confirmPassword) {
      showSnackbar(`Las contraseñas no coinciden`, "error");
      return false;
    }
    return true;
  };

  const navigate = useNavigate();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;
    setLoading(true);

    if (!formData) return;
    try {
      await registerService(formData); // Call your patch API
      showSnackbar(`Usuario actualizado con éxito.`, "success");

      setTimeout(() => navigate(-1), 2000); // Navigate back after 2 seconds
    } catch (error) {
      console.error("Error al actualizar el usuario:", error);
      showSnackbar(`Hubo un error al actualizar el usuario.`, "error");

    } finally {
      setLoading(false);
    }
  };

  const handleChangeSelect = (event: SelectChangeEvent<string>) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };
  // if (!user) {
  //   return <Typography>Error: No user data available.</Typography>;
  // }

  return (
    <CardContent>
      <LoadingModal loading={loading} />
      <SnackbarAlert
        open={snackbar.open}
        message={snackbar.message}
        severity={snackbar.severity}
        onClose={handleSnackbarClose}
      />
      <Typography
        sx={{ marginBottom: 5 }}
        variant="h6"
        fontWeight="bold"
        component="h2"
        gutterBottom
      >
        Crear Sustentante
      </Typography>
      <Box component="form" onSubmit={handleSubmit} noValidate>
        {/* Fields Container */}
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: 2,
            marginBottom: 2,
          }}
        >
          <Box sx={{ flex: "1 1 calc(50% - 16px)" }}>
            {" "}
            {/* Responsive width */}
            <TextField
              label="Nombre"
              name="nombre"
              fullWidth
              value={formData.nombre}
              onChange={handleChange}
              required
            />
          </Box>
          <Box sx={{ flex: "1 1 calc(50% - 16px)" }}>
            <TextField
              label="Apellido Paterno"
              name="apellidoPaterno"
              fullWidth
              value={formData.apellidoPaterno}
              onChange={handleChange}
              required
            />
          </Box>
          <Box sx={{ flex: "1 1 calc(50% - 16px)" }}>
            <TextField
              label="Apellido Materno"
              name="apellidoMaterno"
              fullWidth
              value={formData.apellidoMaterno}
              onChange={handleChange}
              required
            />
          </Box>
          <Box sx={{ flex: "1 1 calc(50% - 16px)" }}>
            <TextField
              label="Correo"
              name="email"
              fullWidth
              value={formData.email}
              onChange={handleChange}
              required
            />
          </Box>
          <Box sx={{ flex: "1 1 calc(50% - 16px)" }}>
            <TextField
              label="Celular"
              name="celular"
              fullWidth
              value={formData.celular}
              onChange={handleChange}
              required
            />
          </Box>
          <Box sx={{ flex: "1 1 calc(50% - 16px)" }}>
            <TextField
              label="Universidad"
              name="universidad"
              fullWidth
              value={formData.universidad}
              onChange={handleChange}
              required
            />
          </Box>
          <Box sx={{ flex: "1 1 100%" }}>
            {" "}
            {/* Full width */}
            <TextField
              label="Cédula Profesional"
              name="cedulaProfesional"
              fullWidth
              value={formData.cedulaProfesional}
              onChange={handleChange}
              required
            />
          </Box>

          <FormControl fullWidth variant="outlined" margin="normal">
            <InputLabel id="estado-select-label">Estado de origen</InputLabel>
            <Select
              labelId="estado-select-label"
              name="estadoOrigen"
              sx={{ textAlign: "left" }}
              value={formData.estadoOrigen}
              onChange={handleChangeSelect}
              label="Estado de origen"
            >
              {estadosMexico.map((estado) => (
                <MenuItem key={estado} value={estado}>
                  {estado}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            fullWidth
            label="Password"
            margin="normal"
            type={showPassword.password ? "text" : "password"}
            name="password"
            value={formData.password}
            onChange={handleChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => togglePasswordVisibility("password")}
                  >
                    {showPassword.password ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <TextField
            fullWidth
            label="Confirmar Password"
            margin="normal"
            type={showPassword.confirmPassword ? "text" : "password"}
            name="confirmPassword"
            value={formData.confirmPassword}
            onChange={handleChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => togglePasswordVisibility("confirmPassword")}
                  >
                    {showPassword.confirmPassword ? (
                      <VisibilityOff />
                    ) : (
                      <Visibility />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>

        {/* Buttons */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: 2,
          }}
        >
          <Button variant="outlined" onClick={() => navigate(-1)}>
            Cancelar
          </Button>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Guardar
          </Button>
        </Box>
      </Box>

    
    </CardContent>
  );
};

export default SustentanteCrear;
