import * as React from "react";
import {
  CardContent,
  Typography,
  Grid2 as Grid,
  TablePagination,
  Box,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  Button,
} from "@mui/material";

import SearchIcon from '@mui/icons-material/Search';
import { Table, TableBody, TableCell, TableHead, TablePaper, TableRow } from "../Table/CustomeTable";
import { useNavigate } from "react-router-dom";
import LoadingModal from "../Loading/LoadingModal";
import { useFetchData } from "../../hooks/services";
import AddIcon from "@mui/icons-material/Add";
import { useAuth } from "../../context/AuthContext";
import { getCertificacionService } from "../../services/certificacionServices";
import { Certificacion } from "../../interfaces/ICertificacion";
import { format } from "date-fns";
import { es } from "date-fns/locale";
import { useEffect } from "react";
import { isTokenExpired } from "../../utils/auth";
import useTokenValidation from "../../hooks/tokenValidation";

import { normalizeText } from "../../utils/utils"; 


const ControlCertificaciones: React.FC = () => {
  const { adminToken, logoutAdmin} = useAuth();
  const { data: certificaciones, loading } = useFetchData(() => getCertificacionService(adminToken?? ""), []);

  const [filteredUsers, setFilteredUsers] = React.useState<any[]>([]);
  const [searchText, setSearchText] = React.useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const navigate = useNavigate(); 

  useEffect(() => {
    if (certificaciones) {
      const filtered = certificaciones.filter((cert: Certificacion) => {
        const searchLower = normalizeText(searchText.toLowerCase());
        return cert.nombre.includes(searchLower) ;

      });
      setFilteredUsers(filtered);
    }
  }, [certificaciones, searchText]);

  
  useTokenValidation(adminToken, logoutAdmin);

  const handlePageChange = (_: any, newPage: number) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
    setPage(0);
  };
  const handleEditClick = (cert: Certificacion) => {
    navigate(`/control/certificaciones/edit`, { state: { cert, edit: true } });
  };
  const handleAddClick = () => {
    console.log("CLICKED")
    navigate('/control/certificaciones/crear');
  };
  
  if (loading) {
    return <LoadingModal loading={loading} />;
  }

  if (!certificaciones || certificaciones.length === 0) {
    return <Typography>No se encontraron certificaciones registrados</Typography>;
  }

  return (
    <CardContent>
      <Grid container spacing={2} sx={{ marginBottom: 2 }}>

        <Grid sx={{ width: '100%', display:'flex'}}> 
          <Box sx={{ width: '60%'}}>
          <Typography variant="h6" fontWeight="bold" component="h2" gutterBottom>
            Certificaciones
          </Typography>
 
          </Box>
          <Button sx={{margin: 'auto'}} variant="contained" onClick={() => handleAddClick()} startIcon={<AddIcon />}>
          Crear
        </Button>
          <FormControl sx={{ m: 1 , width:'35%'}}>
          <InputLabel htmlFor="outlined-adornment-search">Buscar</InputLabel>
          <OutlinedInput
            id="outlined-adornment-search"
            placeholder="Buscar por Nombre"
            startAdornment={<InputAdornment position="start"><SearchIcon></SearchIcon></InputAdornment>}
            label="Amount"
            size="small"
            value={searchText}
            onChange={handleSearchChange}
          />
        </FormControl>
        
        </Grid>
      </Grid>

      <TablePaper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Nombre</TableCell>      
              <TableCell>Descripcion</TableCell>
              <TableCell>Fecha inicio</TableCell>
              <TableCell>Activo</TableCell>
              <TableCell>Número de examenes</TableCell>
              <TableCell>Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredUsers
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((cer: Certificacion) => (
                <TableRow key={cer.id}>
                  <TableCell>{cer.nombre}</TableCell>
                  <TableCell>{cer.descripcion}</TableCell>
                  <TableCell>
                    {cer.fechaInicio
                    ? cer.fechaInicio.split("T")[0].split("-").reverse().join("/")
                    : "Fecha no disponible"}
                  </TableCell>
                  <TableCell>{cer.activo? "Activo": "Inactivo"}</TableCell>
                  <TableCell sx={{textAlign: 'center'}}>{cer.examenes ?? 0}</TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleEditClick(cer)}
                    >
                      Editar
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TablePaper>

      <Box display="flex" justifyContent="flex-end" mt={2}>
        <TablePagination
          component="div"
          count={filteredUsers.length}
          page={page}
          onPageChange={handlePageChange}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleRowsPerPageChange}
          rowsPerPageOptions={[5, 10, 15]}
          labelRowsPerPage="Filas por página"
        />
      </Box>
    </CardContent>
  );
};

export default ControlCertificaciones;
