import React, { useEffect, useState } from "react";
import { Box, Typography, Card, CardContent, Divider, Stack, Button } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIconS from "@mui/icons-material/ErrorSharp";

import { useNavigate, useSearchParams } from "react-router-dom";
import { validateDiploma } from "../../services/examServices";
import { ExamPayment, IValidateDiploma } from "../../interfaces/IUser";
import { useSnackbar } from "../Snackbar/Snackbar";
import SnackbarAlert from "../Snackbar/SnackbarAlert";
import { getExamPay } from "../../services/ventasService";
import { useAuth } from "../../context/AuthContext";
import { AxiosError } from "axios";

const ThankYouPage = () => {

  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);
  const [dataExam, setDataExam] = useState<ExamPayment | null>(null);
  const [error, setError] = useState<boolean>();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const { snackbar, showSnackbar, handleSnackbarClose } = useSnackbar();
  const { user, token} = useAuth();


    useEffect(() => {
      if (!id) {
        navigate("/");
      } else {
        handleRegisterExam();
      }
    }, []);

    const handleRegisterExam = async() => {
console.log(id, "ID")
console.log(token, "TOKEN")
     if (!id || !token) return;
        setLoading(true);
        try {
          const response = await getExamPay(id,token);
          console.log(response,"Response")
          if (response) {
            setDataExam(response);
          }
        } catch (err: any) {
          setError(true);
          console.log(err,"ERROR");

          const errorMessage = err?.message || "Ponte en contacto con nosotros";
          setErrorMessage(errorMessage);
        } finally {
          setLoading(false);
        }
        
      }
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        width: "100vw",
        flexGrow: 1,
        bgcolor: "#f5f5f8",
        position: "absolute",  // Asegura que tome toda la pantalla sin restricciones
        top: 0,
        left: 0,
        padding: 2,
        textAlign: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          maxWidth: 500, // Asegura que no se extienda más de este tamaño
          padding: 3,
        }}
      >
      {error? <>
        <ErrorIconS sx={{ color: "orange", fontSize: 80, marginBottom: 2 }} />
        <Typography variant="h4" gutterBottom>
          Ocurrió un problema al registrar tu pago.
        </Typography>
        <Typography variant="body1" color="error">
          {errorMessage} {/* Aquí se muestra el mensaje de la API */}
        </Typography>
      </>:
      <>
      {dataExam && <>
        
        <CheckCircleIcon sx={{ color: "green", fontSize: 80, marginBottom: 2 }} />
            <Typography variant="h4" gutterBottom>
              ¡Inscripción exitosa!
            </Typography>
                 
            <Card sx={{ maxWidth: 400, marginTop: 3 }}>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Resumen de tu compra
                </Typography>
                <Divider sx={{ marginBottom: 2 }} />
      
                <Stack spacing={1}>
                  
                  <Box display="flex" justifyContent="space-between">
                    <Typography variant="body2" sx={{paddingRight: 1}}>Examen:</Typography>
                    <Typography variant="body2" fontWeight="bold">
                      {dataExam?.examen.nombre}
                    </Typography>
                  </Box>
                  <Box display="flex" justifyContent="space-between">
                    <Typography variant="body2">Precio:</Typography>
                    <Typography variant="body2" fontWeight="bold" color="primary">
                      {new Intl.NumberFormat("es-MX", {
                        style: "currency",
                        currency: "MXN",
                        maximumFractionDigits: 0,
                      }).format(dataExam?.examen?.precioVenta || 0)}
                    </Typography>
                  </Box>
                  <Divider sx={{ marginY: 1 }} />
                  <Box display="flex" justifyContent="space-between">
                    <Typography variant="body1" fontWeight="bold">
                      Total:
                    </Typography>
                    <Typography variant="body1" fontWeight="bold" color="primary">
                      {new Intl.NumberFormat("es-MX", {
                        style: "currency",
                        currency: "MXN",
                        maximumFractionDigits: 0,
                      }).format(dataExam?.montoPagado || 0)}
                    </Typography>
                  </Box>
                  <Button variant="contained" sx={{margin: 2}}
                  onClick={() => navigate("/dashboard/examenes")}>Salir</Button>
                </Stack>
              </CardContent>
            </Card>
      </>}
    </>}
    </Box>
      <SnackbarAlert
        open={snackbar.open}
        message={snackbar.message}
        severity={snackbar.severity}
        onClose={handleSnackbarClose}
      />
    </Box>
  );
};

export default ThankYouPage;
