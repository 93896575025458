import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { IPermisos } from '../interfaces/IUser';

export const ProtectedRouteUser: React.FC = () => {
  const { user, admin , sinodal} = useAuth();

  if (admin || !user || sinodal) {
    return <Navigate to="/" replace />;
  }
  return <Outlet />;
};

export const ProtectedRouteAdmin: React.FC = () => {
  const { user, admin , sinodal} = useAuth();
  const location = useLocation();

  if (user || !admin || sinodal) {
    return <Navigate to="/admin" replace />;
  }
  const matchedPermission: IPermisos | undefined = admin.permisos.find(per => (
    location.pathname.includes(per.modulo) || per.modulo === 'all' )
  );  
 
  if (!matchedPermission) {
    return <Navigate to={"control/monitoreo"} replace />;
  }
  if (matchedPermission.nivel !== "editar") {
    
    const newPath = location.pathname.replace(
      new RegExp(`(/${matchedPermission.modulo})/(crear|edit|)$`, "i"),
      "$1"
    );

    if (newPath !== location.pathname) {
      return <Navigate to={newPath} replace />;
    }
  }
  return <Outlet />;
};

export const ProtectedRouteSinodal: React.FC = () => {
  const { user, admin, sinodal } = useAuth();

  if (admin || !sinodal || user)  {
    return <Navigate to="/" replace />;
  }
  return <Outlet />;
};

