import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import { Outlet, useNavigate } from 'react-router-dom';
import DashboardIcon from "@mui/icons-material/Dashboard";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import QuizIcon from "@mui/icons-material/Quiz";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import ShoppingCart from "@mui/icons-material/ShoppingCart";

import { Avatar, Button, IconButton, Menu, MenuItem } from '@mui/material';
import { useAuth } from '../context/AuthContext';
import logo from '../assets/img/logo.png';
import { useEffect } from 'react';


export default function DashboardL() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

    const navigate = useNavigate();
    const { user, logoutUser , examMode, examModeSet} = useAuth();
    const drawerWidth = 240;

    useEffect( () => {
      examModeSet(false);
    },[]) 

    const navigationItems = [
        {
          text: 'Dashboard',
          icon: <DashboardIcon />,
          onClick: () => navigate("/dashboard"),
        },
        {
          text: 'Exámenes',
          icon: <MenuBookIcon />,
          onClick: () => navigate("/dashboard/examenes"),
        }
        ,
        {
          text: 'Resultados',
          icon: <QuizIcon />,
          onClick: () =>  navigate("/dashboard/resultados")
        },
         {
          text: 'Certificaciones y exámenes',
          icon: <ShoppingCart />,
          onClick: () =>  navigate("/dashboard/inscribir")
        },  
      ];

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1,         backgroundColor: '#ffffff',  }}>
        <Toolbar>
        <Box
          component="img"
          src={logo}
          alt="App Logo"
          sx={{ 
            height: 40, 
            mr: 2 
          }}
        />
        <Typography 
          variant="h6" 
          sx={{ flexGrow: 1, color: 'primary.dark', fontWeight:'bold' }}
        >
          ExamSys
        </Typography>

         <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: 2,
            
          }}
        >
     <div>
      <IconButton onClick={handleMenuOpen} sx={{ height: 30, width: 30 }}>
        <Avatar alt="User Profile" sx={{ width: 32, height: 32 }} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: { 
            width: 220, 
            padding: 2, 
            borderRadius: 2,
            boxShadow: 3 
          }
        }}
      >
        {user && (
          <Box sx={{ mb: 1 }}>
            
            <Typography variant="body1" sx={{ fontWeight: 'bold', color: 'text.primary' }}>
              {user.nombre} {user.apellidoPaterno}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {user.email}
            </Typography>
          </Box>
        )}
        
        <Divider sx={{ my: 1 }} />
        
        <MenuItem onClick={logoutUser} sx={{ fontWeight: 'bold', color: 'secondary.dark' }}>
        Cerrar sesión
        </MenuItem>
      </Menu>
    </div>
        </Box>
        {/* {!user ? (
          <Button 
            onClick={() => navigate('/')}
            sx={{
              backgroundColor: 'primary.main',
              color: 'white',
              borderRadius: '20px',
              padding: '6px 16px',
              '&:hover': { backgroundColor: 'primary.dark' }
            }}
          >
            Login
          </Button>
        ) : (
          <Button 
            onClick={logout}
            sx={{
              backgroundColor: 'grey.300',
              color: 'black',
              borderRadius: '20px',
              padding: '6px 16px',
              '&:hover': { backgroundColor: 'grey.400' }
            }}
          >
            Logout
          </Button>
        )} */}
        </Toolbar>
      </AppBar>
      <Drawer
        variant={examMode? "temporary" : "persistent"}
        open={!examMode}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
        }}
      >
        <Toolbar />
        <Box sx={{ overflow: 'auto' }}>
        <List>
        {navigationItems.map((item) => (
            <ListItem key={item.text} disablePadding>
            <ListItemButton onClick={item.onClick}>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.text} />
            </ListItemButton>
            </ListItem>
        ))}
        </List>
          <Divider />
          {/* <List>
            {['Salir', 'Trash', 'Spam'].map((text, index) => (
              <ListItem key={text} disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                  </ListItemIcon>
                  <ListItemText primary={text} />
                </ListItemButton>
              </ListItem>
            ))}
          </List> */}
        </Box>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Toolbar />
        <Outlet/>
      </Box>
    </Box>
  );
}
