import { serviceUrl } from "../Constants/constants";
import { AdminModulo, EstudiantePractico, Examen, Modulo, Pregunta, ResponseModule, Shop } from "../interfaces/IExam";

import axios from 'axios';
import { ExamenMonitoreo } from "../interfaces/IMonitoreo";
import { IValidateDiploma } from "../interfaces/IUser";

export const fetchExamsService = async (idUsuario: string): Promise<Examen[]> => {
  const response = await axios.get(serviceUrl+'/api/exams/pendientes/usuarios/'+idUsuario);
  return response.data;
};

export const fetchGenerarModules = async (examId: string, userId: string): Promise<ResponseModule> => {
  const response = await axios.get(serviceUrl+'/api/exams/generar/'+examId+'?usuarioId='+userId);
  return response.data;
}; 

export const getAvailableExams = async (): Promise<Examen[]> => {
  const response = await axios.get(serviceUrl+'/api/exams/disponibles');
  return response.data;
};
  
export const getFinishedExams = async (userId: string): Promise<Examen[]> => {
  const response = await axios.get(serviceUrl+'/api/exams/terminados/usuarios/'+userId);
  return response.data;
};
  

export const inscribirExamen = async (usuarioId: string, examenId: string)  => {
  console.log("ENTRA INSCRIBIR EXAMEN");
  try {
     const response = await axios.post<any>(serviceUrl+'/api/exams/inscribir', 
      {
        usuarioId: usuarioId,
        examenId: examenId
      }
     );
     return response.data;

  } catch (error: any) {
      throw new Error(error);
  }
};

export const guardarRespuestasExamen = async (usuarioId: string, examenId: string, examResult: any)  => {
  console.log("ENTRA <SUBIR RESPUESTAS> EXAMEN");
  try {                                 
     await axios.post(serviceUrl+`/api/answers/guardarrespuestas/exam/${examenId}/usuario/${usuarioId}`, examResult)
     .then(response => {
       console.log("Exam submitted:", response.data);
     });

  } catch (error: any) {
      throw new Error(error);
  }
};
export const iniciarExamen = async (usuarioId: string, examenId: string) => {
  const examResult = {
    respuestas: [],
    estatus: "en_progreso",
  };
  console.log("ENTRA <INICIAR EXAMEN> ");
  try {                                 
     await axios.post(serviceUrl+`/api/answers/guardarrespuestas/exam/${examenId}/usuario/${usuarioId}`, examResult)
     .then(response => {
       console.log("Exam started:", response.data);
     });

  } catch (error: any) {
      throw new Error(error);
  }
}
export const terminarExamen = async (usuarioId: string, examenId: string)  => {
  console.log("ENTRA <TERMINAR> EXAMEN");
  try {
     await   axios.put(serviceUrl+`/api/answers/terminar/exam/${examenId}/usuario/${usuarioId}`)
     .then(response => {
       console.log("Exam submitted:", response.data);
     });

  } catch (error: any) {
      throw new Error(error);
  }
};

//-----------------ADMIN EXAMS-----------------//
export const getAdminExams = async (): Promise<Examen[]> => {
  const response = await axios.get(serviceUrl+'/admin/exams/examenes');
  return response.data;
};

export const updateExam = async (idExamen: string, updatedData: any) => {
  const url = serviceUrl+'/admin/exams/'+idExamen;
  const response = await axios.patch(url, updatedData, {
    headers: { "Content-Type": "application/json" },
  });
  return response.data;
};

export const updateModule = async (idModulo: string, updatedData: any) => {  console.log(updatedData);
  console.log("ID MODULO "+idModulo);

  console.log(updatedData);
  const url = serviceUrl+'/admin/exams/modulo/'+idModulo;
  console.log("URL "+url);

  const response = await axios.patch(url, updatedData, {
    headers: { "Content-Type": "application/json" },
  });
  return response.data;
};

//-----------------QUESTIONS EXAMS-----------------//

export const getAdminModules = async ( examenId: string ): Promise<AdminModulo[]> => {
  const response = await axios.get(serviceUrl+'/admin/exams/modulos/'+examenId);
  return response.data;
};
export const getAdminQuestions = async ( examenId: string ): Promise<Pregunta[]> => {
  const response = await axios.get(serviceUrl+'/admin/exams/preguntas/'+examenId);
  return response.data;
};
export const updateQuestion = async (idQuestion: string, updatedData: any) => {
  const url = serviceUrl+'/admin/exams/pregunta/'+idQuestion;
  const response = await axios.patch(url, updatedData, {
    headers: { "Content-Type": "application/json" },
  });
  return response.data;
};

export const updateOption = async (idOption: string, updatedData: any) => {
  const url = serviceUrl+'/admin/exams/opcion/'+idOption;
  const response = await axios.patch(url, updatedData, {
    headers: { "Content-Type": "application/json" },
  });
  return response.data;
};

export const uploadExam = async (formData: any, token: string) => {
  console.log(formData,"AMPTHER ")
    const response = await axios.post(serviceUrl+`/admin/exam/upload`, formData,
      {
        headers: {             'Content-Type': 'multipart/form-data'
          , Authorization: `Bearer ${token}` },
      })
    
  return response.data;
} 

//-----------------SINODAL EXAMS-----------------//


export const getSinodalExams = async (): Promise<Examen[]> => {
  const response = await axios.get(serviceUrl+'/V2/api/exams/disponibles/sinodal');
  return response.data;
};

export const getStudentsSinodal = async (id: string): Promise<EstudiantePractico[]> => {
  console.log("EXAMenes estudiantes "+id);
  const response = await axios.get(`${serviceUrl}/api/exams/${id}/inscritos`);
  return response.data;
};
//


export const fetchSinodalModules = async (examId: string, userId: string): Promise<ResponseModule> => {
  const response = await axios.get(serviceUrl+'/api/exams/generar/'+examId+'?usuarioId='+userId+'&tipoExamen=practico');
  return response.data;
}; 

export const iniciarExamenSinodal = async (usuarioId: string, examenId: string, sinodalId: string) => {
  const examResult = {
    respuestas: [],
    estatus: "en_progreso",
    sinodalId: sinodalId
  };
  console.log("ENTRA <INICIAR EXAMEN> ");
  try {                                 
     await axios.post(serviceUrl+`/api/answers/guardarrespuestas/exam/${examenId}/usuario/${usuarioId}`, examResult)
     .then(response => {
       console.log("Exam started:", response.data);
     });

  } catch (error: any) {
      throw new Error(error);
  }
}

export const validateDiploma = async (idQR: string)  => {
  try {
    const response = await axios.get<IValidateDiploma>(serviceUrl+'/api/diploma/validacion/'+idQR); 
     return response.data;

  } catch (error: any) {
      throw new Error(error);
  }
};


export const searchCertification = async (
  certificacionId: string,
  nombre: string,
  apellidoPaterno: string,
  apellidoMaterno: string
) => {
  try {
    const response = await axios.get<IValidateDiploma>(`${serviceUrl}/calificacion/certificacion`, {
      params: {
        nombre,
        apellidoPaterno,
        apellidoMaterno,
        certificacionId
      },
      headers: {
        "Content-Type": "application/json"
      }
    });

    return response.data;
  } catch (error: any) {
    throw new Error(error.response?.data?.error || error.message);
  }
};

